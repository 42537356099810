import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { EmployeeGroupType } from '__generated__/graphql';
import classNames, { default as classnames } from 'classnames';
import CompanyConnections from 'components/Dashboard/Company/CompanyConnections/CompanyConnections';
import { getCompanyContacts } from 'components/Dashboard/Company/CompanyContact/CompanyContactEmailsPhone';
import CompanyFinancing from 'components/Dashboard/Company/CompanyFinancing/CompanyFinancing';
import CompanyHeader, { CompanyNavbarOptions, nonStealthNavbarOptions, stealthNavbarOptions } from 'components/Dashboard/Company/CompanyHeader/CompanyHeader';
import CompanyLineage from 'components/Dashboard/Company/CompanyLineage/CompanyLineage';
import CompanyOverview from 'components/Dashboard/Company/CompanyOverview/CompanyOverview';
import CompanySocials from 'components/Dashboard/Company/CompanySocials/CompanySocials';
import { FETCH_FOUNDERS_CEO_COUNT, getCompanyFounders } from 'components/Dashboard/Company/CompanyTeam/CompanyFoundersCeo';
import CompanyTeam from 'components/Dashboard/Company/CompanyTeam/CompanyTeam';
import { StickyNavBar } from 'components/Dashboard/Company/StickyNavBar';
import { GET_AFFINITY_LISTS_QUERY } from 'components/Dashboard/Grids/CompanyAffinityFieldsDrawer';
import TopNavigationBar from 'harmonic-components/TopNavigationBar/TopNavigationBar';
import useFlags from 'hooks/useFlags';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import getUsersInNetworkForCompany from 'queries/getUsersInNetworkForCompany';
import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
// eslint-disable-next-line
import useZustandStore from 'stores/zustandStore';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import { COMPANY_TAB_INITIAL_SCROLL_TO_PARAM_KEY, DEFAULT_DASHBOARD_ROUTE, EMPLOYEE_LIST_PAGE_SIZE, PageTitle, SPLITS, TAB_QUERY_PARAM_KEY } from 'utils/constants';
import { useDocumentTitle } from 'utils/hooks';
import { useStore } from '../../../config/StoreProvider';
import { CompanyActions } from './CompanyActions/CompanyActions';
import CompanySidepanel from './CompanySidepanel/CompanySidepanel';
import SimilarCompanies, { getSimilarCompanies } from './SimilarCompanies/SimilarCompanies';
const companyTabQueryKey = TAB_QUERY_PARAM_KEY;
const companyTabInitialScrollToKey = COMPANY_TAB_INITIAL_SCROLL_TO_PARAM_KEY;
export const MAX_WIDTH_CLASS = 'px-p70';
const Company = () => {
    const { enabled: enableCrmIntegration } = useFlags(SPLITS.enableCrmIntegration);
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    const { enabled: enableCompanyLineageTab } = useFlags(SPLITS.showLineageOnCompanyPage);
    const { id } = useParams();
    const companyId = parseInt(id ?? '');
    const [isStickyNavbar, setIsStickyNavbar] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const companyTabQueryParam = searchParams
        .get(companyTabQueryKey)
        ?.toUpperCase();
    const initialSelectedTab = companyTabQueryParam &&
        CompanyNavbarOptions[
        // eslint-disable-next-line
        companyTabQueryParam.toUpperCase()];
    const [selectedNavTab, setSelectedNavTab] = useState(initialSelectedTab ? initialSelectedTab : CompanyNavbarOptions.OVERVIEW);
    const HEADER_HEIGHT_PX = 330;
    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);
    const { rootStore } = useStore();
    const { sidebarOpen } = rootStore.companyViewStore;
    useEffect(() => {
        if (id) {
            rootStore.companyViewStore.setCompany(parseInt(id));
        }
    }, [id, rootStore.companyViewStore]);
    const { company, error, loading } = rootStore.companyViewStore;
    const companyName = company?.data?.name;
    let filteredNavbarOptions = company?.isStealthCompany
        ? stealthNavbarOptions
        : nonStealthNavbarOptions;
    if (!enableCompanyLineageTab) {
        filteredNavbarOptions = filteredNavbarOptions.filter((option) => option.value !== CompanyNavbarOptions.LINEAGE);
    }
    useDocumentTitle(companyName
        ? `${companyName} | ${PageTitle.COMPANY_PAGE_TITLE}`
        : PageTitle.COMPANY_PAGE_TITLE);
    useEffect(() => {
        if (!searchParams.get(companyTabQueryKey)) {
            setSearchParams({
                [companyTabQueryKey]: selectedNavTab
            }, { replace: true });
        }
    }, [selectedNavTab]);
    useEffect(() => {
        const stealthNavValues = stealthNavbarOptions.map((st) => st.value);
        if (company?.isStealthCompany &&
            !stealthNavValues.includes(selectedNavTab)) {
            const newTabValue = CompanyNavbarOptions.OVERVIEW;
            setSelectedNavTab(newTabValue);
            setSearchParams({ [companyTabQueryKey]: newTabValue }, { replace: true });
        }
    }, [company?.isStealthCompany, selectedNavTab, setSearchParams]);
    useEffect(() => {
        // If the page was loaded from another harmonic application and
        // we have some utm content, use it to track a custom event for
        // the profile click.
        const utmSource = searchParams.get('utm_source');
        const utmContent = searchParams.get('utm_content');
        if (utmSource === 'harmonic' && utmContent) {
            analytics.trackCustomEvent({
                event: CustomTrackEvent.COMPANY_PROFILE_CLICK,
                properties: {
                    openedFromLocation: utmContent,
                    companyId: companyId
                }
            });
            // Remove the utm params so we do not attribute the click when
            // a user shares the link via copy/paste.
            searchParams.delete('utm_source');
            searchParams.delete('utm_content');
            setSearchParams(searchParams, { replace: true });
        }
    }, [companyId]);
    const stickNavbar = () => {
        if (window !== undefined) {
            const windowHeight = window.scrollY;
            setIsStickyNavbar(windowHeight > HEADER_HEIGHT_PX);
        }
    };
    const previousPageTitle = useZustandStore((state) => state.previousPageTitle);
    const navigationTitle = previousPageTitle
        ? `${previousPageTitle} / ${companyName}  /`
        : companyName;
    const handleTabChange = (newTabOption, initialScrollTo) => {
        const newSearchParams = {
            [companyTabQueryKey]: newTabOption
        };
        if (initialScrollTo) {
            newSearchParams[companyTabInitialScrollToKey] = initialScrollTo;
        }
        analytics.trackCustomEvent({
            event: CustomTrackEvent.COMPANY_PROFILE_TAB_CHANGE,
            properties: {
                companyId: companyId,
                tabName: newTabOption
            }
        });
        setSearchParams(newSearchParams, { replace: true });
        setSelectedNavTab(newTabOption);
        window.scrollTo(0, 0);
    };
    // Prefetch company financing
    useQuery(getCompanyFinancing, {
        variables: {
            id: companyId
        }
    });
    // Prefetch company actions
    useQuery(getCompaniesAllEntityActions, {
        variables: { ids: [companyId] }
    });
    // Prefetch company affinity lists
    useQuery(GET_AFFINITY_LISTS_QUERY, {
        variables: {
            id: companyId
        },
        skip: !enableCrmIntegration
    });
    // Prefetch employees
    useQuery(getCompanyEmployees, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: EMPLOYEE_LIST_PAGE_SIZE,
            employeeGroupType: EmployeeGroupType.NON_LEADERSHIP,
            userConnectionStatus: null
        }
    });
    // Prefetch executives
    useQuery(getCompanyEmployees, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: EMPLOYEE_LIST_PAGE_SIZE,
            employeeGroupType: EmployeeGroupType.EXECUTIVES,
            userConnectionStatus: null
        }
    });
    // Prefetch metrics
    useQuery(getCompanyMetrics, {
        variables: {
            id: companyId
        }
    });
    // Prefetch similar companies
    useQuery(getSimilarCompanies, {
        variables: {
            urn: `urn:harmonic:company:${companyId}`,
            size: 10
        }
    });
    // Prefetch founders and CEO
    useQuery(getCompanyFounders, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: FETCH_FOUNDERS_CEO_COUNT,
            employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
        }
    });
    // Prefetch contacts
    useQuery(getCompanyContacts, {
        variables: { id: companyId }
    });
    // Prefetch user connections
    useQuery(getUsersInNetworkForCompany, {
        variables: { id: companyId }
    });
    // Prefetch connections page
    // TODO: Reinstate prefetch once we move to more performant query. See: #gql-socket-hangup
    // useQuery<
    //   GetCompanyUserConnectionsWithOverviewAndEventsQuery,
    //   GetCompanyUserConnectionsWithOverviewAndEventsQueryVariables
    // >(getCompanyUserConnectionsWithOverviewAndEvents, {
    //   variables: { id: companyId },
    //   skip: !enableCompanyEngagementTab
    // });
    if (!companyId || companyId < 1 || (error && isNil(company?.data)))
        return _jsx(Navigate, { to: DEFAULT_DASHBOARD_ROUTE });
    // We block the page until the header can be rendered
    if (loading) {
        return (_jsx("div", { "data-testid": "CompanyProfile-Loader", className: "w-full h-full flex justify-center items-center", children: _jsx(PuffLoader, {}) }));
    }
    return (_jsxs("div", { "data-testid": "CompanyProfile", className: classnames('bg-white min-h-screen w-full max-w-[100vw] relative'), children: [isStickyNavbar ? (_jsx("div", { "data-testid": "StickyNavBar", className: "hidden lg:block sticky z-100 top-0 bg-white", children: _jsx(StickyNavBar, { companyLogoUrl: company?.logoUrl, companyId: companyId, companyName: companyName, selectedTab: selectedNavTab, filteredNavbarOptions: filteredNavbarOptions, onTabChange: handleTabChange }) })) : null, _jsx(TopNavigationBar, { name: navigationTitle ?? '' }), _jsx("div", { "data-testid": "CompanyHeader", className: `pt-[53px] relative`, children: _jsx("div", { className: classNames('py-p40', {
                        'border-b border-solid border-border': enableCustomFieldsInCompanyPage
                    }), children: _jsx(CompanyActions, { companyId: companyId, isStealthCompany: company?.isStealthCompany, selectedTab: selectedNavTab, avatarSize: enableCustomFieldsInCompanyPage ? 'x-large' : 'huge' }) }) }), _jsxs("div", { className: "flex", children: [_jsxs("div", { children: [_jsx(CompanyHeader, { companyId: companyId, isStealthCompany: company?.isStealthCompany, selectedTab: selectedNavTab, onTabChange: handleTabChange, filteredNavbarOptions: filteredNavbarOptions }), _jsxs("div", { className: "pb-32 max-w-[2000px] pt-p50 px-[24px] lg:pt-p100 lg:px-[96px]", children: [selectedNavTab === CompanyNavbarOptions.OVERVIEW && (_jsx(CompanyOverview, { isStealthCompany: company?.isStealthCompany, onTabChange: handleTabChange, companyId: companyId })), selectedNavTab === CompanyNavbarOptions.TEAM && (_jsx(CompanyTeam, { companyId: companyId })), selectedNavTab === CompanyNavbarOptions.FINANCING && (_jsx(CompanyFinancing, { companyId: companyId })), selectedNavTab === CompanyNavbarOptions.TRACTION_METRICS && (_jsx(CompanySocials, { companyId: companyId })), selectedNavTab === CompanyNavbarOptions.SIMILAR_COMPANIES && (_jsx(SimilarCompanies, { companyId: companyId })), selectedNavTab === CompanyNavbarOptions.NETWORK && (_jsx(CompanyConnections, { companyId: companyId })), selectedNavTab === CompanyNavbarOptions.LINEAGE && (_jsx(CompanyLineage, { companyId: companyId }))] })] }), enableCustomFieldsInCompanyPage ? (_jsx("div", { className: classNames('border-l border-solid border-border transition-all duration-200 ease-in-out', {
                            'w-0 opacity-0': !sidebarOpen,
                            'w-[400px] opacity-100': sidebarOpen
                        }), children: _jsx(CompanySidepanel, {}) })) : null] })] }));
};
export default observer(Company);
