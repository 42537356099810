import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-unescaped-entities */
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as HarmonicLogo } from 'assets/harmonic-text-logo-new.svg';
import classnames from 'classnames';
import Button from 'components/common/lib/Button';
import { useStore } from 'config/StoreProvider';
import { LogoutReason, UserStatusType } from 'interfaces/Auth';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GENERIC_ACTION_ERROR_MESSAGE, ONBOARDING_ROUTE } from 'utils/constants';
import { acceptPilotAgreement, authenticate } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import { useAuthState } from '../../../hooks/useAppState';
import AgreementText from './AgreementText';
const PilotContractAgreement = () => {
    const [loading, setLoading] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const divRef = useRef(null);
    const navigate = useNavigate();
    const auth = useAuthState();
    const { rootStore } = useStore();
    useEffect(() => {
        const handleScroll = () => {
            const div = divRef.current;
            if (!div)
                return;
            //If user scrolls through 80% of agreement, button is visible
            if (div.scrollTop >= div.scrollHeight * 0.8 - div.clientHeight) {
                setIsAtBottom(true);
            }
            else {
                setIsAtBottom(false);
            }
        };
        const div = divRef.current;
        div?.addEventListener('scroll', handleScroll);
        return () => {
            div?.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const onAcceptAgreement = async () => {
        try {
            if (loading)
                return;
            setLoading(true);
            await acceptPilotAgreement();
            rootStore.authStore.setUserStatus(UserStatusType.ACTIVE);
            navigate(ONBOARDING_ROUTE);
        }
        catch (err) {
            displayToast({
                primaryText: GENERIC_ACTION_ERROR_MESSAGE,
                mode: 'error'
            });
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (!rootStore.authStore.isAuthenticated)
            navigate('/login');
    }, [rootStore.authStore.isAuthenticated, navigate]);
    const verifyUserIsAPilotUser = async () => {
        try {
            const user = await authenticate();
            if (user.status !== UserStatusType.PENDING_PILOT_AGREEMENT_ACCEPTANCE) {
                rootStore.authStore.logout(LogoutReason.SessionExpired);
            }
        }
        catch (err) {
            rootStore.authStore.logout(LogoutReason.SessionExpired, err);
        }
    };
    useEffect(() => {
        verifyUserIsAPilotUser();
    }, []);
    return (_jsxs("div", { children: [_jsx("a", { href: "https://harmonic.ai", className: "head flex justify-center py-2", children: _jsx(HarmonicLogo, { className: "w-52" }) }), _jsx("div", { "data-testid": "pilot-agreement-text", ref: divRef, className: classnames('rounded-lg border border-solid border-clickables-lightStroke max-w-px-750 mx-auto py-7 px-10', 'overflow-scroll max-h-vh-80'), children: _jsx(AgreementText, {}) }), _jsx("div", { className: "mt-5 flex justify-center", children: _jsx(Button, { onClick: onAcceptAgreement, disabled: !isAtBottom, fontSize: "medium", height: "default", width: 10, children: loading ? (
                    //eslint-disable-next-line
                    //@ts-ignore
                    _jsx(CircularProgress, { color: "white", size: 20 })) : !isAtBottom ? ('Scroll to bottom to accept') : ('Accept agreement') }) })] }));
};
export default observer(PilotContractAgreement);
