import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
const TextFieldRenderer = ({ value, onChange, mode }) => {
    const [textValue, setTextValue] = useState(value ?? '');
    useEffect(() => {
        setTextValue(value ?? '');
    }, [value]);
    const handleOnChange = (e) => {
        setTextValue(e.target.value);
    };
    const handleBlur = () => {
        onChange(textValue || null);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    };
    if (mode === 'read') {
        return _jsx("div", { children: value });
    }
    return (_jsx("input", { type: "text", value: textValue, onChange: handleOnChange, onBlur: handleBlur, onKeyDown: handleKeyDown, className: "w-full bg-transparent outline-none", autoFocus: true }));
};
export default TextFieldRenderer;
