import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@headlessui/react';
import { ExcludeInvertedIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import useFlags from 'hooks/useFlags';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SPLITS } from 'utils/constants';
import CompanyResearchAssistant from './CompanyResearchAssistant';
const CompanyResearchAssistantButton = ({ companyName, companyUrn, companyDomain, buttonClassName = 'rounded-md bg-blue-700 text-sm font-medium text-blue-500 hover:bg-blue-800 focus:outline-none', onPopoverOpen, onPopoverClose }) => {
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    // Handle opening and closing the popover
    const handleOpen = () => {
        setIsOpen(true);
        onPopoverOpen?.();
    };
    const handleClose = useCallback(() => {
        setIsOpen(false);
        onPopoverClose?.();
    }, [onPopoverClose]);
    // Add click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen &&
                popoverRef.current &&
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                !popoverRef.current.contains(event.target) &&
                buttonRef.current &&
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                !buttonRef.current.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation();
                handleClose();
            }
        };
        // Use capture phase to intercept events before they reach their targets
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isOpen, handleClose]);
    const handleClick = (e) => {
        e.stopPropagation();
        isOpen ? handleClose() : handleOpen();
    };
    const handleDeadClick = (e) => {
        e.stopPropagation();
    };
    return (_jsxs(Popover, { className: "relative", children: [_jsx(Popover.Button, { ref: buttonRef, className: `${buttonClassName} ${!enableCustomFieldsInCompanyPage ? 'px-3 py-2' : 'px-p30 py-p30'}`, onClick: handleClick, "data-testid": "research-assistant-button", children: _jsx(ExcludeInvertedIcon, { className: "h-4 w-4" }) }), _jsx("div", { className: classNames(isOpen ? 'fixed' : 'hidden', 'inset-0 z-40 cursor-default'), onClick: handleDeadClick, onMouseOver: handleDeadClick }), _jsx("div", { ref: popoverRef, className: classNames(isOpen ? 'absolute' : 'hidden', 'z-50'), onClick: handleDeadClick, children: _jsx(CompanyResearchAssistant, { companyName: companyName, companyUrn: companyUrn, companyDomain: companyDomain }) })] }));
};
export default CompanyResearchAssistantButton;
