import { gql } from '@apollo/client';
import { CustomFieldsFragment } from './getWatchlist';
import { SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT } from './getWatchlistWithCompanies';
export const BASE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT = gql `
  fragment BaseCompanyWatchlistEntryNode on CompanyWatchlistEntryNode {
    __typename
    list {
      __typename
      name
      entityUrn
      id
      customFields {
        ...CustomFields
      }
    }
    ...SimpleCompanyListEntryNode
  }
  ${SIMPLE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT}
  ${CustomFieldsFragment}
`;
export const getListEntriesByCompanyId = gql `
  query GetListEntriesByCompanyId($id: Int!) {
    getCompanyById(id: $id) {
      id
      __typename
      listEntries {
        ...BaseCompanyWatchlistEntryNode
      }
    }
  }
  ${BASE_COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;
