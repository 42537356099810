import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import { urnFrom } from 'common/utils/urn';
import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import ReportDataIssue from 'components/common/ReportDataIssue';
import { EntityType } from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
// eslint-disable-next-line
import { useQuery } from '@apollo/client';
import { SideNavigationIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import InvestorViewToggle from 'components/common/InvestorViewToggle';
import Avatar from 'harmonic-components/Avatar/Avatar';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import React from 'react';
import { SPLITS } from 'utils/constants';
import { isSafeHostedAsset } from 'utils/utilities';
import { useStore } from '../../../../config/StoreProvider';
import { MAX_WIDTH_CLASS } from '../Company';
import { nonStealthNavbarOptions, stealthNavbarOptions } from '../CompanyHeader/CompanyHeader';
import CompanyResearchAssistantButton from '../CompanyResearchAssistant/CompanyResearchAssistantButton';
export const CompanyActions = ({ companyId, isStealthCompany, selectedTab, avatarSize = 'huge' }) => {
    const { data } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const { enabled: enablePushToAffinity } = useFlags(SPLITS.enablePushToAffinity);
    const { enabled: enableNewAddToAffinity } = useFlags(SPLITS.enableNewAddToAffinity);
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    const { enabled: showResearchAgentButtonCompanyProfiles } = useFlags(SPLITS.agentShowResearchAgentButtonCompanyProfiles);
    const { rootStore } = useStore();
    const { sidebarOpen } = rootStore.companyViewStore;
    const navbarOptions = isStealthCompany
        ? stealthNavbarOptions
        : nonStealthNavbarOptions;
    const currentOption = navbarOptions.find((option) => option.value === selectedTab)?.value;
    const companyUrn = urnFrom(EntityType.COMPANY, companyId);
    const logo = data?.getCompanyById?.logoUrl;
    const investorUrn = data?.getCompanyById?.investorUrn;
    const companyLogoUrl = isStealthCompany
        ? stealthLogo
        : isSafeHostedAsset(logo ?? '')
            ? logo
            : defaultCompanyLogo;
    const companyName = data?.getCompanyById?.name;
    const domain = data?.getCompanyById?.website?.domain;
    return (_jsxs("div", { className: classNames('flex flex-col gap-g50 lg:flex-row lg:items-start lg:justify-between', MAX_WIDTH_CLASS), children: [_jsxs("div", { className: "flex gap-g40 items-center", children: [_jsx(Avatar, { src: companyLogoUrl ?? '', alt: "Company Logo", size: avatarSize }), _jsx("p", { className: classNames('text-content-title', {
                            'typography-title-small': enableCustomFieldsInCompanyPage,
                            'typography-title-medium': !enableCustomFieldsInCompanyPage
                        }), children: companyName }), investorUrn ? (_jsx(InvestorViewToggle, { baseEntityUrn: urnFrom(EntityType.COMPANY, companyId), investorUrn: investorUrn })) : null] }), _jsxs("div", { className: "flex flex-row items-center gap-g20 lg:gap-g40", children: [_jsx(AddCompanyToWatchlistButton, { companyId: companyId, enableAffinityLists: enableNewAddToAffinity }), !!enablePushToAffinity && !enableNewAddToAffinity && (_jsx(AddCompanyToAffinityButton, { companyId: companyId, isDisabled: isStealthCompany ?? false, tooltipText: isStealthCompany
                            ? 'Only companies with a website URL can be added'
                            : undefined })), _jsx(LikeCompanyButton, { companyId: companyId }), _jsx(CompanyNotesButton, { companyId: companyId }), !!showResearchAgentButtonCompanyProfiles && !!companyName && (_jsx(CompanyResearchAssistantButton, { companyName: companyName, companyUrn: companyUrn, companyDomain: domain ?? undefined, buttonClassName: "rounded-md bg-blue-50 text-blue-600 px-3 py-2.5 text-sm font-medium hover:bg-blue-100 focus:outline-none transition-colors" })), !!currentOption && (_jsx(ReportDataIssue, { showCircularBorder: true, reportParams: {
                            companyUrn: companyUrn,
                            reportSourceView: ReportSourceView.COMPANY,
                            reportSourceSection: ReportSourceSection[currentOption]
                        } })), enableCustomFieldsInCompanyPage ? (_jsx("div", { className: "border-l border-solid border-border pl-p30", children: _jsx(IconButton, { icon: SideNavigationIcon, onClick: rootStore.companyViewStore.handleToggleSidebar, type: "secondary", isSelected: sidebarOpen, emphasis: "high", size: enableCustomFieldsInCompanyPage ? 'compact' : 'default' }) })) : null] })] }));
};
