import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const SingleSelectRenderer = ({ value, onChange, mode, placeholder }) => {
    const handleOnChange = (e) => {
        onChange(e.target.value);
    };
    if (mode === 'read') {
        return _jsx("div", { children: value });
    }
    return (_jsx("select", { value: value ?? '', onChange: handleOnChange, children: _jsx("option", { value: "", disabled: true, children: placeholder }) }));
};
export default SingleSelectRenderer;
