import { jsx as _jsx } from "react/jsx-runtime";
import { UserStatusType } from 'interfaces/Auth';
import { observer } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import { ACCEPT_PILOT_AGREEMENT_ROUTE, DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';
import { useStore } from '../../config/StoreProvider';
import { useAuthState } from '../../hooks/useAppState';
export const RequireAuth = observer(({ children, disableAccess = false }) => {
    const auth = useAuthState();
    const location = useLocation();
    const { rootStore } = useStore();
    if (rootStore.authStore.isAuthenticated &&
        auth.status === UserStatusType.PENDING_PILOT_AGREEMENT_ACCEPTANCE) {
        return _jsx(Navigate, { to: ACCEPT_PILOT_AGREEMENT_ROUTE });
    }
    if (!rootStore.authStore.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return _jsx(Navigate, { to: "/login", state: { from: location }, replace: true });
    }
    if (disableAccess) {
        return _jsx(Navigate, { to: DEFAULT_DASHBOARD_ROUTE });
    }
    return children;
});
