import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export const ChatMessageText = ({ children }) => {
    const parts = [];
    let lastIndex = 0;
    // Updated regex to include markdown links
    const combinedRegex = /(@\[(.*?)\]\((.*?)\)|\*\*(.*?)\*\*|\[(.*?)\]\((.*?)\))/g;
    let match;
    while ((match = combinedRegex.exec(children)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
            parts.push(children.slice(lastIndex, match.index));
        }
        if (match[0].startsWith('@')) {
            // Handle mention
            parts.push(_jsxs("span", { className: "font-semibold text-blue-600", children: ["@", match[2]] }, match.index));
        }
        else if (match[0].startsWith('*')) {
            // Handle bold text
            parts.push(_jsx("span", { className: "font-semibold", children: match[4] }, match.index));
        }
        else if (match[0].startsWith('[')) {
            // Handle markdown link
            parts.push(_jsx("a", { href: match[6], target: "_blank", rel: "noopener noreferrer", className: "text-blue-600 hover:underline", children: match[5] }, match.index));
        }
        lastIndex = match.index + match[0].length;
    }
    // Add remaining text
    if (lastIndex < children.length) {
        parts.push(children.slice(lastIndex));
    }
    return _jsx("div", { className: "whitespace-pre-wrap", children: parts });
};
