import { getFirebaseToken } from 'actions/fetchActions';
import { AGENT_GRAPH_API_URL } from './constants';
import { logger } from './logger';
export var AgentThreadArtifactType;
(function (AgentThreadArtifactType) {
    AgentThreadArtifactType["COMPANY_SEARCH_QUERY"] = "company_search_query";
})(AgentThreadArtifactType || (AgentThreadArtifactType = {}));
export var AssistantId;
(function (AssistantId) {
    AssistantId["ASSISTANT"] = "453d1cbf-0bdd-5d35-a224-394f372c0502";
    AssistantId["COMPANY_RESEARCH_ASSISTANT"] = "8b49784d-fc34-53d6-a553-2d35de4d3edd";
})(AssistantId || (AssistantId = {}));
export var AgentThreadStepType;
(function (AgentThreadStepType) {
    AgentThreadStepType["USER_MESSAGE"] = "user_message";
    AgentThreadStepType["ASSISTANT_MESSAGE"] = "assistant_message";
    AgentThreadStepType["ARTIFACT"] = "artifact";
})(AgentThreadStepType || (AgentThreadStepType = {}));
export const assistantCreateThread = async () => {
    const apiUrl = `${AGENT_GRAPH_API_URL}/threads`;
    try {
        const token = await getFirebaseToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                'x-harmonic-request-source': 'frontend'
            },
            body: JSON.stringify({
                metadata: {}
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const thread = await response.json();
        return thread;
    }
    catch (error) {
        logger.error('Failed to create thread', {
            error
        });
        throw error;
    }
};
const parseStreamChunk = (rawData, onChunkReceived) => {
    if (rawData.startsWith('data: ')) {
        const chunkRaw = rawData.slice(6).trim();
        try {
            const parsedChunk = JSON.parse(chunkRaw);
            onChunkReceived(parsedChunk);
        }
        catch (error) {
            logger.error('Error parsing chunk', {
                error,
                rawData
            });
        }
    }
};
export const assistantStreamResponse = async ({ assistantId = AssistantId.ASSISTANT, prompt, additionalInput, onChunkReceived, onComplete, threadId, checkpoint, abortSignal, keep }) => {
    const apiUrl = threadId
        ? `${AGENT_GRAPH_API_URL}/threads/${threadId}/runs/stream`
        : `${AGENT_GRAPH_API_URL}/runs/stream`;
    let reader;
    try {
        const token = await getFirebaseToken();
        const payload = {
            assistant_id: assistantId,
            checkpoint: checkpoint,
            input: {
                messages: [
                    {
                        role: 'human',
                        content: prompt
                    }
                ],
                ...additionalInput
            },
            stream_mode: 'events',
            on_completion: keep ? 'keep' : 'delete'
        };
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                'x-harmonic-request-source': 'frontend'
            },
            body: JSON.stringify(payload),
            signal: abortSignal
        });
        if (!response.ok || !response.body) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
        let buffer = '';
        try {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                if (abortSignal?.aborted) {
                    throw new Error('Request aborted');
                }
                const { done, value } = await reader.read();
                if (done) {
                    if (buffer.trim()) {
                        parseStreamChunk(buffer, onChunkReceived);
                    }
                    onComplete();
                    break;
                }
                buffer += value;
                const lines = buffer.split('\n');
                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line) {
                        parseStreamChunk(line, onChunkReceived);
                    }
                }
                buffer = lines[lines.length - 1];
            }
        }
        finally {
            reader.releaseLock();
        }
    }
    catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
            logger.info('Agent graph chat request aborted');
            return;
        }
        logger.error('Agent graph chat request failed', {
            error
        });
        throw error;
    }
};
const _transformThreadStateToAgentThreadMessage = (state, stepType) => {
    return {
        type: stepType,
        message: stepType === AgentThreadStepType.USER_MESSAGE
            ? state.metadata.writes?.__start__.messages[0].content
            : state.metadata.writes?.final_answer.messages[0].content,
        runId: state.metadata.run_id,
        checkpointNs: state.checkpoint.checkpoint_ns ?? null,
        checkpointId: state.checkpoint.checkpoint_id ?? null,
        artifacts: []
    };
};
export const assistantGetThreadHistory = async (threadId) => {
    // TODO: Add pagination layer and transform outside this function
    const apiUrl = `${AGENT_GRAPH_API_URL}/threads/${threadId}/history?limit=100`;
    try {
        const token = await getFirebaseToken();
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                'x-harmonic-request-source': 'frontend'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const rawHistory = await response.json();
        const history = [];
        for (const step of rawHistory) {
            const agentThreadStepType = step.metadata.writes?.__start__
                ? AgentThreadStepType.USER_MESSAGE
                : step.metadata.writes?.final_answer
                    ? AgentThreadStepType.ASSISTANT_MESSAGE
                    : AgentThreadStepType.ARTIFACT;
            if (agentThreadStepType === AgentThreadStepType.USER_MESSAGE ||
                agentThreadStepType === AgentThreadStepType.ASSISTANT_MESSAGE) {
                history.push(_transformThreadStateToAgentThreadMessage(step, agentThreadStepType));
            }
            else if (agentThreadStepType === AgentThreadStepType.ARTIFACT) {
                if (step.metadata.writes?.search_agent) {
                    history[history.length - 1]?.artifacts?.push({
                        name: step.metadata.writes.search_agent.company_search_io.input
                            .company_search_query_truncated ??
                            step.metadata.writes.search_agent.company_search_io.input
                                .company_search_query,
                        type: AgentThreadArtifactType.COMPANY_SEARCH_QUERY,
                        content: step.metadata.writes.search_agent.company_search_io.output
                            ?.current_structured_query
                    });
                }
            }
        }
        history.reverse();
        return history;
    }
    catch (error) {
        logger.error('Failed to fetch chat history', {
            error,
            threadId
        });
        throw error;
    }
};
export const assistantSearchThreads = async (params = {}) => {
    const apiUrl = `${AGENT_GRAPH_API_URL}/threads/search`;
    try {
        const token = await getFirebaseToken();
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
                'x-harmonic-request-source': 'frontend'
            },
            body: JSON.stringify({
                metadata: params.metadata || {},
                values: params.values || {},
                status: params.status || 'idle',
                limit: params.limit || 10,
                offset: params.offset || 0
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const threads = await response.json();
        return threads;
    }
    catch (error) {
        logger.error('Failed to search threads', {
            error,
            params
        });
        throw error;
    }
};
