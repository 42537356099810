import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tabs from 'harmonic-components/Tabs/Tabs';
import { CompanyActions } from './CompanyActions/CompanyActions';
export const StickyNavBar = ({ companyId, selectedTab, filteredNavbarOptions, onTabChange }) => {
    const handleOnTabChange = onTabChange;
    return (_jsxs("div", { className: "flex flex-col gap-g50 w-full h-fit bg-transparent shadow-search px-p70 py-p50", children: [_jsx(CompanyActions, { companyId: companyId, selectedTab: selectedTab, avatarSize: "large" }), _jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
                    ...navbar,
                    variation: 'label'
                })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => 
                // eslint-disable-next-line
                handleOnTabChange(newTabValue) })] }));
};
