import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ColumnAddIcon } from 'assets/harmonic-icons';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import IconButton from 'harmonic-components/IconButton/IconButton';
import * as React from 'react';
import { useCallback } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import FilterByFieldDropdown from './FilterByFieldDropdown';
import { HeaderWithDropdown } from './HeaderWithDropdown';
import { SortDropdownItems } from './SortDropdownItems';
const BulkSelectHeaderWithDropdown = (props) => {
    const { editTableStoreData, editSidebar, allRowsSelected, selectAllStateEnabled, customizeFieldModal } = useShallowTableStore([
        'editTableStoreData',
        'editSidebar',
        'allRowsSelected',
        'selectAllStateEnabled',
        'customizeFieldModal'
    ]);
    const customizeFieldModalOpen = customizeFieldModal?.open;
    const changeSort = props.context.changeSort;
    const resetSelectionState = useCallback(() => {
        editTableStoreData('selectedRowIds', []);
        editTableStoreData('selectAllStateEnabled', false);
        props.api?.deselectAll();
    }, [editTableStoreData, props.api]);
    const colDef = props.column.getColDef();
    const sortField = colDef.searchModelIdentifier;
    React.useEffect(() => {
        resetSelectionState();
    }, [resetSelectionState]);
    const openCreateModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        editSidebar('customizeFieldModal', {
            open: !customizeFieldModalOpen,
            mode: 'add'
        });
    };
    const headerLabelContent = (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-center gap-g50", children: [_jsx("div", { children: _jsx(Checkbox, { variant: allRowsSelected !== null ? 'default' : 'indeterminate', checked: allRowsSelected !== false || selectAllStateEnabled, onChange: props.onCheckBoxChange, dataTestId: "select-scope-checkbox-input", stopPropagation: true }) }), _jsx("span", { className: "text-content-strong typography-label", children: props.displayName })] }), props.isWatchlist && (_jsx("div", { children: _jsx(IconButton, { dataTestId: "add-column-button", icon: ColumnAddIcon, onMouseDown: openCreateModal, onClick: (e) => e.stopPropagation(), size: "compact", type: "secondary", emphasis: "low" }) }))] }));
    return (_jsx(HeaderWithDropdown, { hideInsertLeftField: true, params: props, showDropdown: true, customHeaderLabelContent: headerLabelContent, renderFields: props.renderFields, children: ({ close }) => (_jsxs(_Fragment, { children: [_jsx(SortDropdownItems, { changeSort: changeSort, field: sortField }), props.isWatchlist && (_jsx(FilterByFieldDropdown, { onClose: close, searchField: colDef.filterModelIdentifier }))] })) }));
};
export default BulkSelectHeaderWithDropdown;
