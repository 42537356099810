import { NumberListCustomFieldValueFormat } from '__generated__/graphql';
import dayjs from 'dayjs';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import parsePhoneNumber from 'libphonenumber-js';
import { camelCase, forEach, get, isEqual, isNil, isObjectLike, keyBy, map } from 'lodash';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import { GRID_MAX_SELECT_ALL_ROWS, HARMONIC_ASSETS_URL } from './constants';
/**
 * Determines if an asset is hosted on a safe host.
 * @param url asset url to process
 */
export const isSafeHostedAsset = (url) => {
    return (url.startsWith(HARMONIC_ASSETS_URL) ||
        // HACK! Revert this once profile photo enrichers are fixed
        (url.includes('licdn.com') && url.includes('company-logo')));
};
/**
 * Determine if an element is overflowing its allowed height.
 * @param element HTMLElement
 */
export const isElementOverflow = (element) => {
    if (!element) {
        return false;
    }
    return element.offsetHeight < element.scrollHeight;
};
/**
 * Truncates and formats a money value e.g. 3250000 -> $3.25M
 * @param amount money amount to truncate
 */
export const truncateMoneyValue = (amount, decimalFixedValue = 2, includePrefix = true) => {
    const prefix = includePrefix ? '$' : '';
    return amount === undefined
        ? ''
        : amount < 1000
            ? `${prefix}${amount}`
            : amount < 10000
                ? `${prefix}${addCommasToNumber(amount.toString())}`
                : amount < 1000000
                    ? `${prefix}${(amount / 1000).toFixed(decimalFixedValue)}K`
                    : amount < 1000000000
                        ? `${prefix}${(amount / 1000000).toFixed(decimalFixedValue)}M`
                        : `${prefix}${(amount / 1000000000).toFixed(decimalFixedValue)}B`;
};
export const truncateValue = (value, showDecimals = true, negativeInfiniteLabel = '', positiveInfiniteLabel = '') => {
    return value === Number.NEGATIVE_INFINITY
        ? negativeInfiniteLabel
        : value === Number.POSITIVE_INFINITY
            ? positiveInfiniteLabel
            : !isFinite(value)
                ? ''
                : Math.abs(value) < 1000
                    ? `${value}`
                    : Math.abs(value) < 1000000
                        ? `${(value / 1000).toFixed(showDecimals ? 1 : 0)}K`
                        : Math.abs(value) < 1000000000
                            ? `${(value / 1000000).toFixed(showDecimals ? 1 : 0)}M`
                            : `${(value / 1000000000).toFixed(showDecimals ? 1 : 0)}B`;
};
/**
 * Given a number as a string, format with commas
 * ex. 200000 -> 200,000
 * ex. 1000 -> 1,000
 * ex. 1000.0000 -> 1,000.0000
 */
export const addCommasToNumber = (inputNumber) => {
    if (!inputNumber)
        return '';
    const numberStr = inputNumber.toString();
    const isNegative = numberStr.startsWith('-');
    const numberWithoutSign = isNegative ? numberStr.slice(1) : numberStr;
    const parts = numberWithoutSign.split('.');
    // Format the integer part with commas
    parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    let output = '';
    // If there is a decimal part, join the integer and decimal parts with a dot
    if (parts.length > 1) {
        output = parts.join('.');
    }
    else {
        output = parts[0]; // Return the formatted integer part
    }
    return isNegative ? `-${output}` : output;
};
/**
 * Provided a city, state, and country string, returns a concise formatted string.
 */
export const formatLocation = (city, state, country) => {
    if (!city && !state && !country) {
        return '';
    }
    let stateAbbreviation;
    // US specific state formatting
    if (country == 'United States') {
        const states = {
            Alabama: 'AL',
            Alaska: 'AK',
            'American Samoa': 'AS',
            Arizona: 'AZ',
            Arkansas: 'AR',
            California: 'CA',
            Colorado: 'CO',
            Connecticut: 'CT',
            Delaware: 'DE',
            'District Of Columbia': 'DC',
            'Federated States Of Micronesia': 'FM',
            Florida: 'FL',
            Georgia: 'GA',
            Guam: 'GU',
            Hawaii: 'HI',
            Idaho: 'ID',
            Illinois: 'IL',
            Indiana: 'IN',
            Iowa: 'IA',
            Kansas: 'KS',
            Kentucky: 'KY',
            Louisiana: 'LA',
            Maine: 'ME',
            'Marshall Islands': 'MH',
            Maryland: 'MD',
            Massachusetts: 'MA',
            Michigan: 'MI',
            Minnesota: 'MN',
            Mississippi: 'MS',
            Missouri: 'MO',
            Montana: 'MT',
            Nebraska: 'NE',
            Nevada: 'NV',
            'New Hampshire': 'NH',
            'New Jersey': 'NJ',
            'New Mexico': 'NM',
            'New York': 'NY',
            'North Carolina': 'NC',
            'North Dakota': 'ND',
            'Northern Mariana Islands': 'MP',
            Ohio: 'OH',
            Oklahoma: 'OK',
            Oregon: 'OR',
            Palau: 'PW',
            Pennsylvania: 'PA',
            'Puerto Rico': 'PR',
            'Rhode Island': 'RI',
            'South Carolina': 'SC',
            'South Dakota': 'SD',
            Tennessee: 'TN',
            Texas: 'TX',
            Utah: 'UT',
            Vermont: 'VT',
            'Virgin Islands': 'VI',
            Virginia: 'VA',
            Washington: 'WA',
            'West Virginia': 'WV',
            Wisconsin: 'WI',
            Wyoming: 'WY'
        };
        stateAbbreviation = state ? get(states, state) : state;
        //Edge condition for a particular state which is actually a city
        if ((state === undefined ||
            state === 'District of Columbia' ||
            state === 'Columbia') &&
            city === 'Washington') {
            stateAbbreviation = 'DC';
        }
    }
    // if only one thing is provided, return that
    if (city && !state && !country) {
        return city;
    }
    if (state && !city && !country) {
        return stateAbbreviation || state;
    }
    if (country && !city && !state) {
        return country;
    }
    // special case for US where we want to return city, state
    if (stateAbbreviation) {
        return city ? `${city}, ${stateAbbreviation}` : stateAbbreviation;
    }
    if (city && country) {
        return `${city}, ${country}`;
    }
    if (city && state) {
        return `${city}, ${state}`;
    }
    return '';
};
/**
 * Get a URL query parameter's value
 * @param param URL query parameter to get value
 */
export const getURLParamValue = (param) => {
    if (typeof window === 'undefined')
        return null;
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
};
/**
 * Returns a number abbreviated.
 * @param num asset number to process
 */
export const numberSuffixFormatter = (num) => {
    const numberSuffixes = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
    ];
    const formattingRegx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = numberSuffixes.length - 1; i > 0; i--) {
        if (num >= numberSuffixes[i].value) {
            break;
        }
    }
    return `${(num / numberSuffixes[i].value)
        .toFixed(1)
        .replace(formattingRegx, '$1')}${numberSuffixes[i].symbol}`;
};
/**
 * Returns a number as a string, with a + appended if it is at the maximum.
 * @param num number as input
 * @param max maximum number, at which to add a +
 */
export const truncateNumberToMax = (num, max) => {
    return num >= max ? `${max}+` : `${num}`;
};
// Removes all iteration of keys within deep nested object
export const deepOmitFromObject = (obj, keysToOmit) => {
    const keysToOmitIndex = keyBy(keysToOmit); // create an index object of the keys that should be omitted
    function omitFromObject(obj) {
        // the inner function which will be called recursivley
        return transform(obj, function (result, value, key) {
            // transform to a new object
            if (key in keysToOmitIndex) {
                // if the key is in the index skip it
                return;
            }
            // If needed, run nested objects through the inner function - omitFromObject
            if (isObject(value)) {
                result[key] = omitFromObject(value);
            }
            else if (isArray(value)) {
                result[key] = map(value, omitFromObject);
            }
            else {
                result[key] = value;
            }
        });
    }
    return omitFromObject(obj); // return the inner function result
};
// Formats code area of doc removing empty spaces, indentation fix.
// Copied from syntax highlighting library github section.
export const formatCodeBlock = (code) => {
    let skippedLeadingEmptyLines = false;
    let lastLineIdx = 0;
    let indentation = Number.MAX_SAFE_INTEGER;
    let numRemovedLines = 0;
    function processNonEmptyLine(line, i) {
        lastLineIdx = i - numRemovedLines;
        indentation = Math.min(indentation, Math.max(0, line.search(/[^ \t]/)));
        return [line.trimRight()];
    }
    const lines = `${code}`
        .split('\n')
        .flatMap((line, i) => {
        if (!skippedLeadingEmptyLines) {
            if (line.match(/^[ \t]*$/)) {
                numRemovedLines += 1;
                return [];
            }
            skippedLeadingEmptyLines = true;
            return processNonEmptyLine(line, i);
        }
        if (line.match(/^[ \t]*$/)) {
            return [''];
        }
        return processNonEmptyLine(line, i);
    })
        .slice(0, lastLineIdx + 1);
    if (lines.length === 0) {
        return '';
    }
    return (indentation !== 0 ? lines.map((line) => line.substring(indentation)) : lines).join('\n');
};
/**
 * Appends numSpaces spaces before each line in a multiline string
 * @param str string to transform
 * @param numSpaces number of leading spaces to prepend to each line
 */
export const addLeadingSpaces = (str, numSpaces) => {
    return str
        .trim()
        .split(/\r?\n/)
        .map((line) => `${' '.repeat(numSpaces)}${line}`)
        .join('\n');
};
// Return string with ... if it is over limit
export const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
};
// To mainly check external URL.
// with or without http/https
export const isExternalURL = (url) => {
    const res = url.match(
    //eslint-disable-next-line
    /^(?:https?:\/\/(?:www\.)?|https:(?:\/\/)?)?\w+(?:[-.]\w+)+(?:\/[^\/\s]+)*|\bmailto:.+$/g);
    return res !== null;
};
/**
 * Checks if a string can be converted to a real number
 * @param str string to check if it is numeric
 * @returns true if the string is numeric, false if it is not
 */
export const isNumeric = (str) => {
    return !isNaN(parseInt(str));
};
// Extracts only numbers from a string
export const returnNumberFromString = (str) => {
    const num = str.replace(/[^0-9-]/g, '');
    return num;
};
export const getDurationText = (startDate, endDate) => {
    if (!(dayjs(startDate).isValid() && dayjs(endDate).isValid())) {
        return '';
    }
    const yearDiff = dayjs(endDate).diff(startDate, 'years');
    const monthDiff = dayjs(endDate).diff(startDate, 'months') % 12;
    if (yearDiff !== 0) {
        const monthDiffText = monthDiff > 0
            ? `, ${monthDiff} ${monthDiff === 1 ? 'month' : 'months'}`
            : '';
        return `${yearDiff > 1 ? `${yearDiff} years` : '1 year'}${monthDiffText}`;
    }
    if (monthDiff === 0)
        return '';
    return monthDiff === 1 ? '1 month' : `${monthDiff} months`;
};
// Recursively replaces the value with our replacement if value strictly equals to pattern
// replaces the existing object in place.
export const replaceValueFromObject = (source, pattern, replacement) => {
    const recursiveReplace = (objSource) => {
        if (typeof objSource !== 'object') {
            return pattern === objSource ? replacement : objSource;
        }
        if (typeof objSource === 'object') {
            if (objSource === null) {
                return null;
            }
            Object.keys(objSource).forEach((property) => {
                objSource[property] = recursiveReplace(objSource[property]);
            });
            return objSource;
        }
    };
    return recursiveReplace(source);
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const snakelize = (obj) => transform(obj, (acc, value, key, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);
    acc[snakeKey] = isObject(value) ? snakelize(value) : value;
});
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const camelize = (obj) => transform(obj, (acc, value, key) => {
    const camelKey = isArray(obj) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelize(value) : value;
});
const getAllHiddenSavedSearchesKey = (userUrn) => `${userUrn}-hiddenSavedSearches`;
export const getAllHiddenSavedSearches = (userUrn) => {
    const localStorageKey = getAllHiddenSavedSearchesKey(userUrn);
    const hiddenSavedSearches = localStorage.getItem(localStorageKey);
    if (!hiddenSavedSearches) {
        localStorage.setItem(localStorageKey, '[]');
        return [];
    }
    return JSON.parse(hiddenSavedSearches);
};
/**
 * @deprecated this defaults to all page locations other than /people to be a company search.
 * what about /person? use @getEntityListTypeFromURL instead
 */
export const getResourceTypeFromURL = () => {
    if (!window) {
        throw new Error('window is not defined');
    }
    const currentUrl = window.location.pathname;
    return currentUrl.includes('/people')
        ? ApiResourceType.PeopleList
        : ApiResourceType.CompaniesList;
};
export const getClosestNumberIndexInAnArray = (num, arr) => {
    if (num === Number.NEGATIVE_INFINITY) {
        return 0;
    }
    if (num === Number.POSITIVE_INFINITY) {
        return arr.length - 1;
    }
    let curr = arr[0];
    let diff = Math.abs(num - curr);
    let index = 0;
    for (let val = 0; val < arr.length; val++) {
        const newdiff = Math.abs(num - arr[val]);
        if (newdiff <= diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        }
    }
    return index;
};
export const convertInfinityToNull = (obj) => {
    forEach(obj, (value, key) => {
        if (isObjectLike(value)) {
            convertInfinityToNull(value);
        }
        else if (isEqual(value, Infinity)) {
            obj[key] = null;
        }
    });
    return obj;
};
export const formatCustomerType = (customerType, typedTags, shouldUseV2Tags) => {
    return shouldUseV2Tags
        ? typedTags
            ?.filter((tag) => tag?.tag_type === 'CUSTOMER_TYPE')
            ?.map((tag) => tag?.tag_value)
        : customerType;
};
export var shouldUseV2TagsEnum;
(function (shouldUseV2TagsEnum) {
    shouldUseV2TagsEnum["V1"] = "v1";
    shouldUseV2TagsEnum["V2"] = "v2";
    shouldUseV2TagsEnum["BOTH"] = "both";
})(shouldUseV2TagsEnum || (shouldUseV2TagsEnum = {}));
export const formatSpecificTags = (typedTags, tagType, typedTagsV2, tagTypeV2, shouldUseV2Tags) => {
    if (shouldUseV2Tags === shouldUseV2TagsEnum.BOTH) {
        const v2Tags = typedTagsV2
            ?.filter((tag) => tag && tagTypeV2.includes(tag.tag_type))
            ?.map((tag) => tag?.tag_value) || [];
        const v1Tags = typedTags
            ?.filter((tag) => tag && tagType.includes(tag.tag_type))
            ?.map((tag) => tag?.tag_value) || [];
        return [...v1Tags, ...v2Tags];
    }
    return shouldUseV2Tags === shouldUseV2TagsEnum.V2
        ? typedTagsV2
            ?.filter((tag) => tag && tagTypeV2.includes(tag.tag_type))
            ?.map((tag) => tag?.tag_value)
        : typedTags
            ?.filter((tag) => tag && tagType.includes(tag.tag_type))
            ?.map((tag) => tag?.tag_value);
};
export const formatTags = (typedTags, typedTagsV2, shouldUseV2Tags) => {
    const desiredOrder = ['MARKET_VERTICAL', 'TECHNOLOGY_TYPE'];
    if (shouldUseV2Tags === shouldUseV2TagsEnum.BOTH) {
        const v2Tags = (typedTagsV2 || [])
            .filter((tag) => desiredOrder.indexOf(tag?.tag_type || '') !== -1)
            .sort((tagA, tagB) => {
            if (!tagA || !tagB)
                return -1; // hack to quite down the typechecker
            const tagAIndex = desiredOrder.indexOf(tagA.tag_type);
            const tagBIndex = desiredOrder.indexOf(tagB.tag_type);
            if (tagAIndex === -1) {
                return 1;
            }
            else if (tagBIndex === -1) {
                return -1;
            }
            else {
                return tagAIndex - tagBIndex;
            }
        });
        const v1Tags = typedTags || [];
        return [...v1Tags, ...v2Tags];
    }
    return shouldUseV2Tags === shouldUseV2TagsEnum.V2
        ? (typedTagsV2 || [])
            .filter((tag) => desiredOrder.indexOf(tag?.tag_type || '') !== -1)
            .sort((tagA, tagB) => {
            if (!tagA || !tagB)
                return -1; // hack to quite down the typechecker
            const tagAIndex = desiredOrder.indexOf(tagA.tag_type);
            const tagBIndex = desiredOrder.indexOf(tagB.tag_type);
            if (tagAIndex === -1) {
                return 1;
            }
            else if (tagBIndex === -1) {
                return -1;
            }
            else {
                return tagAIndex - tagBIndex;
            }
        })
        : typedTags;
};
export const fundingAttributeNullStatusToDisplayText = (fundingAttributeNullStatus) => {
    if (fundingAttributeNullStatus === 'EXISTS_BUT_UNDISCLOSED') {
        return 'Undisclosed';
    }
    return 'Unknown';
};
export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber)
        return null;
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
    if (!parsedPhoneNumber)
        return null;
    return parsedPhoneNumber?.formatInternational();
};
export const parseFundingStage = (stage) => {
    const fundingType = (stage && get(SearchValueToDisplayNameMap, stage)) ??
        SearchValueToDisplayNameMap['undisclosed'];
    return fundingType;
};
export const convertEnrichmentErrorToText = (error) => {
    switch (error) {
        case 'PROFILE_NOT_FOUND':
            return 'Profile not found';
        case 'INVALID_CANONICALS':
            return 'Invalid url';
        case 'INVALID_CUSTOM_FIELD_VALUES':
            return 'Invalid custom field values';
        case 'INVALID_URN':
            return 'Invalid urn';
        default:
            return 'Unknown error';
    }
};
export const singularOrPlural = (singular, count) => {
    return count === 1 ? singular : `${singular}s`;
};
export const hostnameFromURL = (url) => {
    // Remove the protocol (http or https)
    let simplified = url.replace(/^https?:\/\//, '');
    // Remove 'www' if it exists
    simplified = simplified.replace(/^www\./, '');
    return simplified;
};
export const makeNamePosessive = (name) => {
    return (name =
        name.charAt(name.length - 1) == 's' ? `${name}'` : `${name}'s`);
};
export const parseNumberListCustomFieldValue = (value) => {
    const strippedValue = value.replace(/[^0-9.-]/g, ''); // Allow '-' for negative numbers
    if (strippedValue.startsWith('-') && strippedValue.length === 1)
        return '-';
    if (!value || !strippedValue || isNaN(Number(strippedValue)))
        return undefined;
    return strippedValue;
};
export const isNumberAndNaN = (value) => {
    return typeof value == 'number' && isNaN(value);
};
export const formatNumberListCustomFieldValue = (value, format, includeSymbol = false) => {
    if (isNil(value) || isNumberAndNaN(value))
        return '';
    if (format === NumberListCustomFieldValueFormat.PERCENT && includeSymbol) {
        return `${addCommasToNumber(value.toString())}%`;
    }
    if (format === NumberListCustomFieldValueFormat.US_DOLLAR && includeSymbol) {
        //format decimal to 2 places if it has decimal
        let formattedValue = value.toString();
        if (!Number.isInteger(Number(value))) {
            formattedValue = Number(value).toFixed(2);
        }
        return `$${addCommasToNumber(formattedValue.toString())}`;
    }
    return addCommasToNumber(value.toString());
};
export const formatPercentage = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value);
};
export const paginate = async ({ axiosInstance, url, size, getResultsFromData, onData, onComplete, onError, body, sort }) => {
    let cursor = null;
    let hasNextPage = true;
    let completed = 0;
    const promises = [];
    let errorMessage = '';
    while (hasNextPage && completed < GRID_MAX_SELECT_ALL_ROWS) {
        let finalUrl = `${url}?size=${size}`;
        if (sort) {
            finalUrl += `&sort_field=${sort?.sortField}&sort_descending=${sort?.descending}`;
        }
        if (cursor) {
            finalUrl += `&cursor=${cursor}`;
        }
        let response;
        try {
            if (body) {
                response = await axiosInstance.post(finalUrl, body);
            }
            else {
                response = await axiosInstance.get(finalUrl);
            }
        }
        catch (e) {
            errorMessage = `Failed while fetching page: ${e}`;
            break;
        }
        const { data } = response;
        if (data?.page_info?.has_next) {
            cursor = data?.page_info?.next;
        }
        else {
            hasNextPage = false;
        }
        const results = getResultsFromData(data);
        if (results?.length > 0) {
            promises.push(onData(results));
        }
        completed += results?.length ?? 0;
    }
    const results = await Promise.allSettled(promises);
    const failed = results.filter((result) => result.status === 'rejected');
    if (failed.length > 0) {
        errorMessage = `Failed to process ${failed.length} onData requests`;
    }
    const totalCompleted = completed - failed.length * size;
    if (errorMessage && onError) {
        onError(totalCompleted > 0 ? totalCompleted : 0, errorMessage);
    }
    if (onComplete && totalCompleted > 0) {
        await onComplete(totalCompleted);
    }
};
export function isValidUrl(potentialUrl) {
    // eslint-disable-next-line no-useless-escape
    return /^(https?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(potentialUrl);
}
