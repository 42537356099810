import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import FoundingDate from 'components/common/FoundingDate';
import Location from 'components/common/Location';
import PersonUserConnections from 'components/common/PersonUserConnections';
import RelevanceScore from 'components/common/RelevanceScore';
import Socials from 'components/common/Social/Socials';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { MAX_WIDTH_CLASS } from '../Company';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { compact, get } from 'lodash';
import { ReactComponent as ChevronLeftIcon } from 'assets/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import { Badge } from 'common/components';
import { isHarmonicUser, urnFrom } from 'common/utils/urn';
import NetworkBadgeWithTooltip from 'components/common/Network/NetworkBadgeWithTooltip';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { EntityType } from 'hooks/useDashboardLocation';
import useFlags from 'hooks/useFlags';
import getUsersInNetworkForCompany from 'queries/getUsersInNetworkForCompany';
import { useEffect, useRef, useState } from 'react';
import useStore from 'stores/zustandStore';
import { SPLITS } from 'utils/constants';
import { getFundingStageBadgeColor } from 'utils/funding';
import CompanyAbout from '../CompanyOverview/CompanyAbout';
export var CompanyNavbarOptions;
(function (CompanyNavbarOptions) {
    CompanyNavbarOptions["OVERVIEW"] = "OVERVIEW";
    CompanyNavbarOptions["TEAM"] = "TEAM";
    CompanyNavbarOptions["FINANCING"] = "FINANCING";
    CompanyNavbarOptions["SIMILAR_COMPANIES"] = "SIMILAR_COMPANIES";
    CompanyNavbarOptions["TRACTION_METRICS"] = "TRACTION_METRICS";
    CompanyNavbarOptions["NETWORK"] = "NETWORK";
    CompanyNavbarOptions["LINEAGE"] = "LINEAGE";
})(CompanyNavbarOptions || (CompanyNavbarOptions = {}));
export const nonStealthNavbarOptions = [
    {
        value: CompanyNavbarOptions.OVERVIEW,
        label: 'Overview'
    },
    {
        value: CompanyNavbarOptions.TEAM,
        label: 'Team '
    },
    {
        value: CompanyNavbarOptions.FINANCING,
        label: 'Funding'
    },
    {
        value: CompanyNavbarOptions.TRACTION_METRICS,
        label: 'Traction Metrics'
    },
    {
        value: CompanyNavbarOptions.LINEAGE,
        label: 'Lineage'
    },
    {
        value: CompanyNavbarOptions.SIMILAR_COMPANIES,
        label: 'Similar Companies'
    },
    {
        value: CompanyNavbarOptions.NETWORK,
        label: 'Network'
    }
];
export const stealthNavbarOptions = [
    {
        value: CompanyNavbarOptions.OVERVIEW,
        label: 'Overview'
    },
    {
        value: CompanyNavbarOptions.NETWORK,
        label: 'Network',
        inBeta: true
    }
];
const CompanyHeader = ({ companyId, isStealthCompany, selectedTab, onTabChange, filteredNavbarOptions }) => {
    // Scroll buttons for the mobile navbar
    const scrollContainerRef = useRef(null);
    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(false);
    const handleOnTabChange = onTabChange;
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            checkScrollButtons();
            scrollContainer.addEventListener('scroll', checkScrollButtons);
            window.addEventListener('resize', checkScrollButtons);
            return () => {
                scrollContainer.removeEventListener('scroll', checkScrollButtons);
                window.removeEventListener('resize', checkScrollButtons);
            };
        }
    }, []);
    const previousPageTitle = useStore((state) => state.previousPageTitle);
    const { data } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const customerUrn = useStore((state) => state.customerUrn);
    const { data: usersInNetworkData } = useQuery(getUsersInNetworkForCompany, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const { enabled: useNetworkMappingNewTooltips } = useFlags(SPLITS.networkMappingNewTooltips);
    const userConnections = compact(compact(usersInNetworkData?.getCompanyById?.usersInNetwork).map((connection) => connection.user)) ?? [];
    if (!data?.getCompanyById)
        return null;
    const companyName = data.getCompanyById.name;
    const location = data.getCompanyById.location;
    const foundingDate = data.getCompanyById.foundingDate?.date;
    const companyStage = data.getCompanyById.stage;
    const convertedFundingStage = (companyStage && get(SearchValueToDisplayNameMap, companyStage)) ?? '';
    const website = data.getCompanyById.website;
    const linkedin = data.getCompanyById.socials?.linkedin;
    const crunchbase = data.getCompanyById.socials?.crunchbase;
    const pitchbook = data.getCompanyById.socials?.pitchbook;
    const angellist = data.getCompanyById.socials?.angellist;
    const twitter = data.getCompanyById.socials?.twitter;
    const facebook = data.getCompanyById.socials?.facebook;
    const relevanceScore = data.getCompanyById.relevanceScore;
    const instagram = data.getCompanyById.socials?.instagram;
    const fundingInspectorUrl = `https://harmonic.retool.com/app/fundinginspector#companyId=${companyId}`;
    const companyCorrectorUrl = `https://harmonic.retool.com/app/company-data-corrector#companyId=${companyId}`;
    const relevanceScoreComponent = (_jsxs("div", { className: "flex flex-row items-center gap-x-g20 lg:gap-x-g40", children: [relevanceScore && _jsx(RelevanceScore, { score: relevanceScore }), convertedFundingStage && (_jsx(Badge, { label: convertedFundingStage, color: getFundingStageBadgeColor(convertedFundingStage), size: "large", intensity: "subtle", dataTestId: "CompanyHeader-FundingStage" }))] }));
    const socialMediaComponent = (_jsx(Socials, { id: companyId, type: "company", websiteUrl: website?.url, linkedinUrl: linkedin?.url, crunchbaseUrl: crunchbase?.url, pitchbookUrl: pitchbook?.url, angellistUrl: angellist?.url, twitterUrl: twitter?.url, facebookUrl: facebook?.url, instagramUrl: instagram?.url, ...(isHarmonicUser(customerUrn) && {
            fundingInspectorUrl: fundingInspectorUrl,
            companyCorrectorUrl: companyCorrectorUrl
        }) }));
    const locationAndFoundingDate = (location || foundingDate) && (_jsxs("div", { className: "flex items-center", children: [location && (_jsx(Location, { city: location?.city, state: location?.state, country: location?.country, fontSize: "small" })), foundingDate && (_jsx("div", { className: "ml-p50", children: _jsx(FoundingDate, { date: foundingDate }) }))] }));
    const linkedInNetwork = !useNetworkMappingNewTooltips &&
        userConnections.length > 0 && (_jsxs("div", { className: "flex items-center justify-between gap-g20", children: [_jsx("button", { onClick: () => onTabChange(CompanyNavbarOptions.NETWORK), children: _jsx(PersonUserConnections, { userConnections: userConnections }) }), _jsx("div", { children: _jsx(UserConnectionsTooltip, {}) })] }));
    const companyAboutComponent = _jsx(CompanyAbout, { companyId: companyId });
    const checkScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setShowLeftScroll(scrollLeft > 0);
            setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
        }
    };
    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // Adjust this value as needed
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };
    const mobileNavBar = (_jsxs("div", { className: "relative", children: [showLeftScroll && (_jsx("button", { onClick: () => scroll('left'), className: "absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-r from-white to-transparent pl-p20 pr-p40 py-p20", children: _jsx(ChevronLeftIcon, { className: "w-4 h-4 text-content-primary" }) })), showRightScroll && (_jsx("button", { onClick: () => scroll('right'), className: "absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-l from-white to-transparent pr-p20 pl-p40 py-p20", children: _jsx(ChevronRightIcon, { className: "w-4 h-4 text-content-primary" }) })), _jsx("div", { ref: scrollContainerRef, className: "overflow-x-auto scrollbar-hide relative", children: _jsx("div", { className: "flex w-full min-w-max", children: _jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
                            ...navbar,
                            variation: 'label'
                        })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => onTabChange(newTabValue) }) }) })] }));
    return (_jsx("div", { children: _jsxs("div", { "data-testid": "CompanyHeader", className: `pt-[12px] relative`, children: [_jsxs("div", { className: `${MAX_WIDTH_CLASS}`, children: [_jsxs("div", { className: "flex flex-col pb-p60 gap-g50", children: [_jsxs("div", { className: "flex gap-g40", children: [useNetworkMappingNewTooltips ? (_jsx(NetworkBadgeWithTooltip, { usersInNetwork: userConnections.map((user) => ({
                                                name: user.name ?? '',
                                                email: user.email ?? ''
                                            })), includeSummaryText: true, targetEntityUrn: urnFrom(EntityType.COMPANY, companyId), targetName: companyName ?? 'Unknown', onClick: () => handleOnTabChange(CompanyNavbarOptions.NETWORK), targetRelevantExperienceCompanyUrn: urnFrom(EntityType.COMPANY, companyId) })) : null, relevanceScoreComponent] }), _jsxs("div", { className: "flex gap-x-g50 gap-y-g50 flex-col", children: [_jsxs("div", { className: "flex flex-col gap-g50 lg:flex-row", children: [socialMediaComponent, locationAndFoundingDate] }), linkedInNetwork] }), companyAboutComponent] }), _jsx("div", { className: "lg:hidden", children: mobileNavBar })] }), _jsxs("div", { className: classNames('hidden lg:flex lg:w-full'), children: [_jsx("div", { className: "pl-p70 border-b border-solid border-border" }), _jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
                                ...navbar,
                                variation: 'label'
                            })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => 
                            // eslint-disable-next-line
                            handleOnTabChange(newTabValue) }), _jsx("div", { className: "pr-p70 border-b border-solid border-border" })] })] }) }));
};
export default CompanyHeader;
