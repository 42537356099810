import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const MultiSelectRenderer = ({ value, onChange, mode, placeholder }) => {
    const handleOnChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        onChange(selectedOptions);
    };
    if (mode === 'read') {
        return _jsx("div", { children: value?.join(', ') ?? '' });
    }
    return (_jsx("select", { multiple: true, value: value ?? [], onChange: handleOnChange, children: _jsx("option", { value: "", disabled: true, children: placeholder }) }));
};
export default MultiSelectRenderer;
