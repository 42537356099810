import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown, ChevronUp, CompanyListIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import Accordion from 'harmonic-components/Accordion/Accordion';
import CustomFieldRenderer from 'harmonic-components/CustomFields/CustomFieldRenderer';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
import { ListCustomFieldTypeToIconMap } from 'utils/custom-fields';
import ListStackItemLabel from './ListIStacktemLabel';
const ListStackItem = ({ listEntry, onSetCustomFieldValues }) => {
    const [open, setOpen] = useState(false);
    const handleToggleOpen = () => {
        setOpen(!open);
    };
    const handleSetCustomFieldValues = (input) => {
        onSetCustomFieldValues(input);
    };
    return (_jsxs("div", { "data-testid": `list-stack-item-${listEntry.list.urn}`, children: [_jsxs("div", { onClick: handleToggleOpen, "aria-expanded": open, className: classNames('w-full flex p-p20 gap-g40 bg-surface-default justify-between', 'items-center rounded-tl-br40 rounded-tr-br40 hover:bg-int-overlay-secondary-hover hover:cursor-pointer active:bg-int-overlay-secondary-pressed', {
                    'rounded-bl-br40 rounded-br-br40': !open
                }), children: [_jsxs("div", { className: "flex px-p40 py-p10 items-center gap-g20", children: [_jsx(CompanyListIcon, { className: "w-4 h-4 text-content-weak" }), _jsx("p", { className: "typography-label-medium-normal text-content-strong", children: listEntry.list.name })] }), _jsxs("div", { className: "flex items-center gap-g30", children: [_jsx("p", { className: "typography-label-small-default text-content-weak", children: "3d ago" }), _jsx(IconButton, { dataTestId: "ListStackItem-ToggleBtn", onClick: handleToggleOpen, type: "secondary", emphasis: "low", icon: open ? ChevronUp : ChevronDown, size: "compact" })] })] }), _jsx(Accordion, { open: open, children: _jsx("div", { "data-testid": "ListStackItem-Content", className: "bg-surface-default p-p20 rounded-bl-br40 rounded-br-br40", children: listEntry.list.customFields.map((customField) => {
                        if (!customField) {
                            return null;
                        }
                        const value = listEntry.customFieldValues.find((value) => value.customField.urn === customField.urn);
                        return (_jsxs("div", { className: "grid grid-cols-2", children: [_jsx(ListStackItemLabel, { label: customField.name, icon: ListCustomFieldTypeToIconMap[customField.type] }), _jsx(CustomFieldRenderer, { value: value?.value || null, onChange: (newValue) => handleSetCustomFieldValues({
                                        customFieldUrn: customField.urn,
                                        watchlistUrn: listEntry.list.urn,
                                        entryUrns: [listEntry.urn],
                                        customFieldValueInput: newValue
                                    }), customField: customField, mode: "read" }, customField.urn)] }, customField.urn));
                    }) }) })] }));
};
export default ListStackItem;
