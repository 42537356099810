import { jsx as _jsx } from "react/jsx-runtime";
import TextInput from 'harmonic-components/TextInput/TextInput';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { isValidUrl } from 'utils/utilities';
const WebsiteRenderer = ({ value, onChange, mode, placeholder }) => {
    const [editValue, setEditValue] = useState(value || '');
    const [hasChanged, setHasChanged] = useState(false);
    const handleOnChange = (e) => {
        setEditValue(e.target.value);
        setHasChanged(true);
    };
    const handleOnSubmit = (submitValue) => {
        if (!hasChanged) {
            return;
        }
        onChange(submitValue);
        setHasChanged(false);
    };
    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnSubmit(editValue);
        }
    };
    if (mode === 'read') {
        if (isEmpty(editValue)) {
            return (_jsx("div", { className: "text-input-placeholder-default", children: placeholder }));
        }
        let url = editValue;
        if (!editValue.startsWith('http') && !editValue.startsWith('https')) {
            url = 'https://' + editValue;
        }
        if (isValidUrl(url)) {
            return (_jsx("p", { className: "line-clamp-2", children: _jsx("a", { className: "underline", href: editValue, rel: "noopener noreferrer", target: "_blank", children: editValue }) }));
        }
        else {
            return _jsx("p", { className: "typography-paragraph line-clamp-2", children: editValue });
        }
    }
    return (_jsx(TextInput, { value: editValue, onChange: handleOnChange, paddingOverrideClasses: "py-p20 px-p30", placeholder: placeholder, autoFocus: true, onBlur: () => handleOnSubmit(editValue), onKeyDown: handleOnKeyDown }));
};
export default WebsiteRenderer;
