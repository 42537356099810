import useStore from '../zustandStore';
import AffinityStore from './AffinityStore';
import AuthStore from './AuthStore';
import CompaniesExploreViewStore from './CompaniesExploreViewStore';
import CompanyStore from './CompanyStore';
import CompanyViewStore from './CompanyViewStore';
import CompanyWatchlistStore from './CompanyWatchlistStore';
import CompanyWatchlistViewStore from './CompanyWatchlistViewStore';
import FieldSpecStore from './FieldSpecStore';
import PeopleExploreViewStore from './PeopleExploreViewStore';
import PeopleWatchlistStore from './PeopleWatchlistStore';
import PeopleWatchlistViewStore from './PeopleWatchlistViewStore';
import PersonStore from './PersonStore';
import PersonViewStore from './PersonViewStore';
import SavedSearchStore from './SavedSearchStore';
import SavedSearchViewStore from './SavedSearchViewStore';
import SplitStore from './SplitStore';
class RootStore {
    companiesExploreViewStore;
    peopleExploreViewStore;
    authStore;
    savedSearchStore;
    savedSearchViewStore;
    companyWatchlistViewStore;
    peopleWatchlistViewStore;
    companyWatchlistStore;
    peopleWatchlistStore;
    fieldSpecStore;
    splitStore;
    companyStore;
    companyViewStore;
    affinityStore;
    personStore;
    personViewStore;
    constructor() {
        this.companiesExploreViewStore = new CompaniesExploreViewStore(this);
        this.peopleExploreViewStore = new PeopleExploreViewStore(this);
        this.authStore = new AuthStore(this);
        this.savedSearchStore = new SavedSearchStore(this);
        this.savedSearchViewStore = new SavedSearchViewStore(this);
        this.companyWatchlistViewStore = new CompanyWatchlistViewStore(this);
        this.peopleWatchlistViewStore = new PeopleWatchlistViewStore(this);
        this.companyWatchlistStore = new CompanyWatchlistStore(this);
        this.peopleWatchlistStore = new PeopleWatchlistStore(this);
        this.fieldSpecStore = new FieldSpecStore(this);
        this.splitStore = new SplitStore(this);
        this.companyStore = new CompanyStore(this);
        this.companyViewStore = new CompanyViewStore(this);
        this.affinityStore = new AffinityStore(this);
        this.personStore = new PersonStore(this);
        this.personViewStore = new PersonViewStore(this);
    }
    setTitle({ documentTitle, pageTitle }) {
        document.title = documentTitle;
        useStore.getState().editStoreData('pageTitle', pageTitle);
    }
}
const rootStore = new RootStore();
export default rootStore;
