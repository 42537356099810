import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloClient, useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';
import { NoteTextIcon } from 'assets/harmonic-icons';
import axios from 'axios';
import classnames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import Tabs from 'harmonic-components/Tabs/Tabs';
import useFlags from 'hooks/useFlags';
import { CompanyCustomTextScope } from 'interfaces/DataModel/Company';
import { debounce } from 'lodash';
import { getCompanyNotes } from 'queries/getCompanyNotes';
import React, { useCallback, useEffect, useState } from 'react';
import { NOTE_SAVE_ERROR, SPLITS } from 'utils/constants';
import { logger } from 'utils/logger';
import { updateCompanyCustomText } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import CompanyIconButtonActionLoader from './CompanyIconButtonActionLoader';
const CompanyNotesButton = ({ companyId }) => {
    const { enabled: enableCustomFieldsInCompanyPage } = useFlags(SPLITS.enableCustomFieldsInCompanyPage);
    const client = useApolloClient();
    const [notesPopoverAnchorEl, setNotesPopoverAnchorEl] = useState(null);
    const NotesPopoverOpen = Boolean(notesPopoverAnchorEl);
    const handleNotesPopoverClose = () => {
        setNotesPopoverAnchorEl(null);
    };
    const handleNotesPopoverOpen = (event) => {
        event.stopPropagation();
        setNotesPopoverAnchorEl(event.currentTarget);
    };
    const { data, loading } = useQuery(getCompanyNotes, {
        variables: { id: companyId },
        fetchPolicy: 'no-cache'
    });
    const [userNotesValue, setUserNotesValue] = useState('');
    const [teamNotesValue, setTeamNotesValue] = useState('');
    const [userNoteCancelToken, setUserNoteCancelToken] = useState(null);
    const [teamNoteCancelToken, setTeamNoteCancelToken] = useState(null);
    const [tabValue, setTabValue] = useState('my_notes');
    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };
    const cancelToken = axios.CancelToken;
    const debouncedSaveUserNoteFunction = useCallback(debounce(async (newNote) => {
        const source = cancelToken.source();
        //Cancel previous request to avoid race condition
        if (userNoteCancelToken)
            userNoteCancelToken.cancel();
        setUserNoteCancelToken(source);
        try {
            await updateCompanyCustomText(client, companyId, newNote, CompanyCustomTextScope.USER, source.token);
        }
        catch (err) {
            if (!axios.isCancel(err)) {
                displayToast({
                    primaryText: 'Error saving note',
                    mode: 'error'
                });
                logger.error('Company user note save error', {
                    error: err
                });
            }
        }
    }, 500), []);
    const debouncedSaveTeamNoteFunction = useCallback(debounce(async (newNote) => {
        const source = cancelToken.source();
        //Cancel previous request to avoid race condition
        if (teamNoteCancelToken)
            teamNoteCancelToken.cancel();
        setTeamNoteCancelToken(source);
        try {
            await updateCompanyCustomText(client, companyId, newNote, CompanyCustomTextScope.TEAM, source.token);
        }
        catch (err) {
            if (!axios.isCancel(err)) {
                displayToast({
                    primaryText: NOTE_SAVE_ERROR,
                    mode: 'error'
                });
                logger.error('Company team note save error', {
                    error: err
                });
            }
        }
    }, 500), []);
    const handleUserNoteChange = (e) => {
        const newNoteValue = e.target.value;
        setUserNotesValue(newNoteValue);
        debouncedSaveUserNoteFunction(newNoteValue);
    };
    const handleTeamNoteChange = (e) => {
        const newNoteValue = e.target.value;
        setTeamNotesValue(newNoteValue);
        debouncedSaveTeamNoteFunction(newNoteValue);
    };
    useEffect(() => {
        if (!loading) {
            setUserNotesValue(data?.getCompanyById?.userNotes || '');
            setTeamNotesValue(data?.getCompanyById?.teamNotes || '');
        }
    }, [data, loading]);
    if (loading)
        return _jsx(CompanyIconButtonActionLoader, {});
    return (_jsxs("div", { "data-testid": "CompanyNotesButton", children: [_jsx(IconButton, { icon: NoteTextIcon, onClick: handleNotesPopoverOpen, type: "secondary", emphasis: "high", size: enableCustomFieldsInCompanyPage ? 'compact' : 'default' }), _jsx(Popover, { open: NotesPopoverOpen, anchorEl: notesPopoverAnchorEl, onClose: handleNotesPopoverClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }, className: "mt-1 max-h-96", classes: {
                    paper: 'shadow-static-elevation-floating'
                }, onClick: (e) => e.stopPropagation(), disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs("div", { "data-testid": "CompanyNotes", className: "bg-white w-96 rounded-md", children: [_jsx(Tabs, { tabs: [
                                {
                                    label: 'My Notes',
                                    value: 'my_notes',
                                    variation: 'label'
                                },
                                {
                                    label: 'Team Notes',
                                    value: 'team_notes',
                                    variation: 'label'
                                }
                            ], selectedTabValue: tabValue, onTabChange: handleTabChange }), _jsxs("div", { className: "p-p20", children: [tabValue === 'my_notes' && (_jsx("textarea", { rows: 7, value: userNotesValue, onChange: handleUserNoteChange, placeholder: "Empty", className: classnames('mt-2 p-2 rounded-md text-content-default typography-label leading-4 bg-transparent w-full', 'resize-none border border-solid border-transparent') })), tabValue === 'team_notes' && (_jsx("textarea", { rows: 7, value: teamNotesValue, onChange: handleTeamNoteChange, placeholder: "Empty", className: classnames('mt-2 p-2 rounded-md text-content-default typography-label leading-4 bg-transparent w-full', 'resize-none border border-solid border-transparent') }))] })] }) })] }));
};
export default CompanyNotesButton;
