import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const PersonRenderer = ({ value, onChange, mode, placeholder }) => {
    const handleOnChange = (e) => {
        onChange([e.target.value]);
    };
    if (mode === 'read') {
        return _jsx("div", { children: value });
    }
    return (_jsx("input", { type: "text", value: value ?? '', onChange: handleOnChange, placeholder: placeholder }));
};
export default PersonRenderer;
