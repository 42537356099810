import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
import ListStackEmptyState from './ListStackEmptyState';
import ListStackItem from './ListStackItem';
const LIST_STACK_MAX_ENTRIES = 5;
const ListStack = ({ listEntries, onSetCustomFieldValues }) => {
    const [showingMore, setShowingMore] = useState(false);
    const handleSetCustomFieldValues = (input) => {
        onSetCustomFieldValues(input);
    };
    const handleShowMore = () => {
        setShowingMore(!showingMore);
    };
    const handleShowLess = () => {
        setShowingMore(false);
    };
    const listEntriesToShow = showingMore
        ? listEntries
        : listEntries.slice(0, LIST_STACK_MAX_ENTRIES);
    return (_jsxs("div", { className: "bg-surface-background p-p20 rounded-br40", children: [_jsxs("div", { className: "pt-p10 pb-p40 pl-p40 pr-p20 flex justify-between items-center", children: [_jsx("p", { className: "typography-label-medium-strong", children: "Lists" }), _jsx(IconButton, { icon: PlusIcon, size: "tiny" })] }), !listEntries.length && _jsx(ListStackEmptyState, {}), !!listEntries.length && (_jsx("div", { className: "flex flex-col gap-g30", children: listEntriesToShow.map((listEntry) => (_jsx(ListStackItem, { listEntry: listEntry, onSetCustomFieldValues: handleSetCustomFieldValues }, listEntry.list.urn))) })), _jsxs("div", { className: "flex justify-center items-center pt-p20", children: [listEntries.length > LIST_STACK_MAX_ENTRIES && !showingMore && (_jsx(Button, { onClick: handleShowMore, dataTestId: "list-stack-show-more-button", label: `+ ${listEntries.length - LIST_STACK_MAX_ENTRIES} more`, type: "secondary", size: "tiny", emphasis: "low", fullWidth: true })), !!showingMore && (_jsx(Button, { onClick: handleShowLess, label: "Show less", type: "secondary", size: "tiny", emphasis: "low", fullWidth: true }))] })] }));
};
export default ListStack;
