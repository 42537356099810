import { DateListCustomFieldValueFormat, ListCustomFieldType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { CustomFieldType } from 'stores/mobx/interfaces';
import { CalendarIcon, CheckboxIcon, MultiSelectIcon, NumberIcon, PersonCircleIcon, SelectIcon, StatusIcon, TextIcon, URLIcon } from '../assets/harmonic-icons';
export const mapCustomFieldsToColumnConfig = (customFieldHeaders, watchlistCustomFields = []) => {
    const customColumnConfigs = [];
    customFieldHeaders.map((header) => {
        const customField = watchlistCustomFields.find((field) => field.urn === header.field);
        if (customField) {
            const CustomFieldIcon = EditFieldTypeOptionIcon[customField.type];
            customColumnConfigs.push({
                urn: header.field ?? '',
                label: header.headerName ?? '',
                value: header.field ?? '',
                icon: CustomFieldIcon
            });
        }
    });
    return customColumnConfigs;
};
export const formatCustomFieldDate = (format, date) => {
    let formattedDate = date;
    if (!date)
        return '';
    switch (format) {
        case DateListCustomFieldValueFormat.DD_MM_YYYY:
            formattedDate = dayjs(date).format('DD/MM/YYYY');
            break;
        case DateListCustomFieldValueFormat.MM_DD_YYYY:
            formattedDate = dayjs(date).format('MM/DD/YYYY');
            break;
        case DateListCustomFieldValueFormat.YYYY_MM_DD:
            formattedDate = dayjs(date).format('YYYY/MM/DD');
            break;
        default:
            break;
    }
    return formattedDate;
};
export const formatGraphqlCustomFieldToMobxCustomField = (customField) => {
    return {
        urn: customField.urn,
        name: customField.name,
        type: CustomFieldType[customField.type],
        metadata: {
            dateFormat: customField.metadata?.__typename === 'DateListCustomFieldMetadata'
                ? customField.metadata?.dateFormat
                : undefined,
            numberFormat: customField.metadata?.__typename === 'NumberListCustomFieldMetadata'
                ? customField.metadata?.numberFormat
                : undefined
        }
    };
};
export const ListCustomFieldTypeToIconMap = {
    [ListCustomFieldType.TEXT]: TextIcon,
    [ListCustomFieldType.NUMBER]: NumberIcon,
    [ListCustomFieldType.DATE]: CalendarIcon,
    [ListCustomFieldType.SINGLE_SELECT]: SelectIcon,
    [ListCustomFieldType.MULTI_SELECT]: MultiSelectIcon,
    [ListCustomFieldType.PERSON]: PersonCircleIcon,
    [ListCustomFieldType.STATUS]: StatusIcon,
    [ListCustomFieldType.CHECKBOX]: CheckboxIcon,
    [ListCustomFieldType.WEBSITE]: URLIcon
};
export const EditFieldTypeOptions = [
    {
        label: 'Text',
        value: ListCustomFieldType.TEXT,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.TEXT]
    },
    {
        label: 'Number',
        value: ListCustomFieldType.NUMBER,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.NUMBER]
    },
    {
        label: 'Person',
        value: ListCustomFieldType.PERSON,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.PERSON]
    },
    {
        label: 'Date',
        value: ListCustomFieldType.DATE,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.DATE]
    },
    {
        label: 'Status',
        value: ListCustomFieldType.STATUS,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.STATUS]
    },
    {
        label: 'Single select',
        value: ListCustomFieldType.SINGLE_SELECT,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.SINGLE_SELECT]
    },
    {
        label: 'Multi select',
        value: ListCustomFieldType.MULTI_SELECT,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.MULTI_SELECT]
    },
    {
        label: 'URL',
        value: ListCustomFieldType.WEBSITE,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.WEBSITE]
    },
    {
        label: 'Checkbox',
        value: ListCustomFieldType.CHECKBOX,
        icon: ListCustomFieldTypeToIconMap[ListCustomFieldType.CHECKBOX]
    }
];
export const EditFieldTypeOptionIcon = EditFieldTypeOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.icon }), 
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
{});
export const EditFieldTypeOptionLabel = EditFieldTypeOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.label }), 
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
{});
export const isSingleSelectCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.SINGLE_SELECT;
};
export const isMultiSelectCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.MULTI_SELECT;
};
export const isTextCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.TEXT;
};
export const isDateCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.DATE;
};
export const isNumberCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.NUMBER;
};
export const isPersonCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.PERSON;
};
export const isWebsiteCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.WEBSITE;
};
export const isStatusCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.STATUS;
};
export const isCheckboxCustomFieldValue = (value) => {
    return value.customField.type === ListCustomFieldType.CHECKBOX;
};
