import gql from 'graphql-tag';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const GET_PEOPLE_WATCHLIST = gql `
  query GetPeopleWatchlist($idOrUrn: String!) {
    getPeopleWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
export const PEOPLE_WATCHLIST_MOCK = {
    __typename: 'PeopleWatchlist',
    creator: {
        entityUrn: 'urn:harmonic:user:2885',
        email: 'john@harmonic.ai',
        __typename: 'User'
    },
    owner: {
        entityUrn: 'urn:harmonic:user:2885',
        email: 'john@harmonic.ai',
        __typename: 'User'
    },
    id: '5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
    entityUrn: 'urn:harmonic:people_watchlist:5ab230cc-f93a-4cfb-82f7-25bcf3fe6664',
    name: 'People #1',
    sharedWithTeam: true,
    userWatchlistType: 'USER_MANAGED_WATCHLIST'
};
export const GET_PEOPLE_WATCHLIST_MOCKED_RESPONSE = {
    getPeopleWatchlistByIdOrUrn: {
        ...PEOPLE_WATCHLIST_MOCK,
        customFields: [
            {
                __typename: 'PeopleListCustomField',
                urn: 'urn:harmonic:person_list_custom_field:1155',
                name: 'Text field',
                type: 'TEXT',
                createdAt: '2025-02-08T04:11:48Z',
                defaultValue: {
                    textDefaultValue: '',
                    __typename: 'TextCustomFieldValue'
                },
                metadata: null
            },
            {
                __typename: 'PeopleListCustomField',
                urn: 'urn:harmonic:person_list_custom_field:1156',
                name: 'Select custom field',
                type: 'SINGLE_SELECT',
                createdAt: '2025-02-08T04:13:45Z',
                defaultValue: null,
                metadata: {
                    __typename: 'SelectListCustomFieldMetadata',
                    options: [
                        {
                            color: 'expressive-45',
                            name: 'Option 1',
                            urn: 'urn:harmonic:select_list_custom_field_value_option:b164fdb7-8dce-48b1-9966-2ba2c9d1745a'
                        },
                        {
                            color: 'expressive-5',
                            name: 'Option 2',
                            urn: 'urn:harmonic:select_list_custom_field_value_option:ed2e9e7c-fdff-4eaf-8f1f-77aad120be55'
                        }
                    ]
                }
            }
        ],
        namedViews: [
            {
                id: 16538,
                entityUrn: 'urn:harmonic:person_list_named_view:16538',
                name: 'All',
                groupByField: null,
                hideEmptyColumns: false,
                visibleColumns: [
                    'urn:harmonic:person_list_custom_field:1155',
                    'urn:harmonic:person_field:person_name',
                    'urn:harmonic:person_field:person_position_title',
                    'urn:harmonic:person_field:person_position_company_id',
                    'urn:harmonic:person_field:person_linkedin_url',
                    'urn:harmonic:person_field:person_education_experience_school',
                    'urn:harmonic:person_field:person_highlights_category_list',
                    'urn:harmonic:person_field:person_customers_connections',
                    'urn:harmonic:person_field:person_customers_connections_last_email_person_email',
                    'urn:harmonic:person_field:person_customers_connections_last_email_at',
                    'urn:harmonic:person_field:person_customers_connections_last_meeting_person_email',
                    'urn:harmonic:person_field:person_customers_connections_last_meeting_at',
                    'urn:harmonic:person_field:person_emails',
                    'urn:harmonic:person_field:person_linkedin_headline',
                    'urn:harmonic:person_field:person_last_company_id',
                    'urn:harmonic:person_field:person_location'
                ],
                searchQuery: {
                    filterGroup: {
                        joinOperator: 'and',
                        filters: [],
                        filterGroups: [],
                        filterGroupGenerators: null
                    },
                    pagination: {
                        start: null,
                        pageSize: 25
                    },
                    sort: [
                        {
                            sortField: 'person_entry_created_at',
                            descending: true,
                            sortContextValues: null
                        }
                    ],
                    controlledFilterGroup: null,
                    mergeQueryForNestedFields: false
                },
                displayType: 'GRID',
                __typename: 'PersonListNamedView'
            }
        ]
    }
};
