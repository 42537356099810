import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useState } from 'react';
import { CustomFieldType } from 'stores/mobx/interfaces';
import { logger } from 'utils/logger';
import CheckboxRenderer from './CheckboxRenderer';
import DateRenderer from './DateRenderer';
import MultiSelectRenderer from './MultiSelectRenderer';
import NumberRenderer from './NumberRenderer';
import PersonRenderer from './PersonRenderer';
import SingleSelectRenderer from './SingleSelectRenderer';
import StatusRenderer from './StatusRenderer';
import TextFieldRenderer from './TextFieldRenderer';
import WebsiteRenderer from './WebsiteRenderer';
const BaseCustomFieldRenderer = ({ value, onChange, mode, customField }) => {
    const handleTextValueChange = (newValue) => {
        onChange({ textData: { value: newValue ?? '' } });
    };
    const handleSingleSelectValueChange = (newValue) => {
        onChange({ selectData: { value: newValue } });
    };
    const handleMultiSelectValueChange = (newValue) => {
        onChange({ multiSelectData: { value: newValue ?? [] } });
    };
    const handleCheckboxValueChange = (newValue) => {
        onChange({ checkboxData: { value: newValue } });
    };
    const handleNumberValueChange = (newValue) => {
        onChange({ numberData: { value: newValue } });
    };
    const handleDateValueChange = (newValue) => {
        onChange({ dateData: { value: newValue } });
    };
    const handlePersonValueChange = (newValue) => {
        onChange({ personData: { value: newValue ?? [] } });
    };
    const handleStatusValueChange = (newValue) => {
        onChange({ selectData: { value: newValue } });
    };
    const handleWebsiteValueChange = (newValue) => {
        onChange({ websiteData: { value: newValue } });
    };
    if (customField.type === CustomFieldType.TEXT) {
        if (value !== null && typeof value !== 'string') {
            logger.error('Text value is not a string', { value });
            return null;
        }
        return (_jsx(TextFieldRenderer, { mode: mode, placeholder: "Add text value", value: value, onChange: handleTextValueChange }));
    }
    if (customField.type === CustomFieldType.SINGLE_SELECT) {
        if (typeof value !== 'string' && value !== null) {
            logger.error('Single select value is not a string', { value });
            return null;
        }
        return (_jsx(SingleSelectRenderer, { mode: mode, placeholder: "Add single select value", value: value, onChange: handleSingleSelectValueChange }));
    }
    if (customField.type === CustomFieldType.MULTI_SELECT) {
        if (!Array.isArray(value) && value !== null) {
            logger.error('Multi select value is not an array', { value });
            return null;
        }
        return (_jsx(MultiSelectRenderer, { mode: mode, placeholder: "Add multi select value", value: value, onChange: handleMultiSelectValueChange }));
    }
    if (customField.type === CustomFieldType.CHECKBOX) {
        if (typeof value !== 'boolean' && value !== null) {
            logger.error('Checkbox value is not a boolean', { value });
            return null;
        }
        return (_jsx(CheckboxRenderer, { mode: mode, placeholder: "Add checkbox value", value: value, onChange: handleCheckboxValueChange }));
    }
    if (customField.type === CustomFieldType.NUMBER) {
        if (typeof value !== 'number' && value !== null) {
            logger.error('Number value is not a number', { value });
            return null;
        }
        return (_jsx(NumberRenderer, { mode: mode, placeholder: `Add ${customField.name}`, value: value, numberFormat: customField.metadata?.numberFormat, onChange: handleNumberValueChange }));
    }
    if (customField.type === CustomFieldType.DATE) {
        if (typeof value !== 'string' && value !== null) {
            logger.error('Date value is not a string', { value });
            return null;
        }
        if (!customField.metadata?.dateFormat) {
            logger.error('Date format is not set', { customField });
            return null;
        }
        return (_jsx(DateRenderer, { mode: mode, placeholder: "Add date value", value: value, format: customField.metadata?.dateFormat, onChange: handleDateValueChange }));
    }
    if (customField.type === CustomFieldType.PERSON) {
        if (!Array.isArray(value) && value !== null) {
            logger.error('Person value is not an array', { value });
            return null;
        }
        return (_jsx(PersonRenderer, { mode: mode, placeholder: "Add person value", value: value, onChange: handlePersonValueChange }));
    }
    if (customField.type === CustomFieldType.STATUS) {
        if (typeof value !== 'string' && value !== null) {
            logger.error('Status value is not a string', { value });
            return null;
        }
        return (_jsx(StatusRenderer, { mode: mode, placeholder: "Add status value", value: value, onChange: handleStatusValueChange }));
    }
    if (customField.type === CustomFieldType.WEBSITE) {
        if (typeof value !== 'string' && value !== null) {
            logger.error('Website value is not a string', { value });
            return null;
        }
        return (_jsx(WebsiteRenderer, { mode: mode, placeholder: "Add URL", value: value, onChange: handleWebsiteValueChange }));
    }
    logger.error(`Unsupported custom field type: ${customField.type}`, { value });
    return null;
};
const withEditableField = (WrappedComponent) => {
    return (props) => {
        const [isEditing, setIsEditing] = useState(props.mode === 'edit');
        const handleClick = () => {
            if (props.mode === 'read') {
                setIsEditing(true);
            }
        };
        // On blur impacts library like date picker and select. Maybe we want to use slightly different approach
        const handleBlur = () => {
            setIsEditing(false);
            return;
        };
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                setIsEditing(false);
            }
            else if (e.key === 'Escape') {
                setIsEditing(false);
            }
        };
        return (_jsx("div", { onClick: handleClick, onBlur: handleBlur, onKeyDown: handleKeyDown, className: classNames('flex flex-col justify-center items-start truncate cursor-pointer rounded-br30 bg-input-placeholder hover:bg-int-overlay-secondary-hover active:bg-int-overlay-secondary-pressed', 'typography-label-default-default', !isEditing && 'pl-p30 text-content-default'), children: _jsx(WrappedComponent, { ...props, mode: isEditing ? 'edit' : 'read' }) }));
    };
};
const CustomFieldRenderer = ({ readOnly, ...props }) => {
    if (readOnly) {
        return _jsx(BaseCustomFieldRenderer, { ...props, mode: "read" });
    }
    const EditableField = withEditableField(BaseCustomFieldRenderer);
    return _jsx(EditableField, { ...props });
};
export default CustomFieldRenderer;
