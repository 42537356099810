import { makeAutoObservable, runInAction } from 'mobx';
import { COMPANY_ROUTE, DASHBOARD_ROUTE } from '../../utils/constants';
class CompanyViewStore {
    rootStore;
    company = null;
    loading = false;
    sidebarOpen = false;
    error = undefined;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    setCompany(id) {
        this.resetCompany();
        this.fetchAndSetCompany(id);
    }
    handleToggleSidebar = () => {
        this.sidebarOpen = !this.sidebarOpen;
    };
    resetCompany() {
        this.company = null;
        this.loading = false;
        this.error = undefined;
    }
    async fetchAndSetCompany(companyId) {
        runInAction(() => {
            this.loading = true;
        });
        const { company, error } = await this.rootStore.companyStore.get(companyId);
        runInAction(() => {
            this.company = company;
            this.error = error;
            this.loading = false;
        });
        // If the company returned by API differs (i.e. in the case of a merge), redirect to the actual company page
        if (company?.data?.id !== companyId) {
            window.history.pushState(null, '', DASHBOARD_ROUTE + COMPANY_ROUTE + '/' + company?.data?.id);
        }
    }
}
export default CompanyViewStore;
