import { makeAutoObservable, runInAction } from 'mobx';
class PersonViewStore {
    rootStore;
    person = null;
    loading = false;
    error = undefined;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    setPerson(id) {
        this.resetPerson();
        this.fetchAndSetPerson(id);
    }
    resetPerson() {
        this.person = null;
        this.loading = false;
        this.error = undefined;
    }
    async fetchAndSetPerson(personId) {
        runInAction(() => {
            this.loading = true;
        });
        const { person, error } = await this.rootStore.personStore.get(personId);
        runInAction(() => {
            this.person = person;
            this.error = error;
            this.loading = false;
        });
    }
}
export default PersonViewStore;
