import { AuthErrorCodes, LogoutReason } from 'interfaces/Auth';
import { makeAutoObservable, runInAction } from 'mobx';
import { getFirebaseToken } from 'actions/fetchActions';
import { getIdFromUrn } from 'common/utils/urn';
import 'config/firebase';
import { getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, onIdTokenChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { appStore } from 'hooks/useAppStore';
import { AuthStatus } from 'interfaces/Auth';
import { toast } from 'react-toastify';
import usePersistedZustandStore from 'stores/persistedZustandStore';
import useStore from 'stores/zustandStore';
import analytics from 'utils/analytics';
import { HARMONIC_CUSTOMER_URN, IS_PROD_LIKE_ENV, LOCAL_AGAINST_PROD, SESSION_EXPIRED_ERROR_MESSAGE } from 'utils/constants';
import { logger } from 'utils/logger';
import { authenticate } from 'utils/midtierApi';
class AuthStore {
    rootStore;
    token;
    user;
    userMetadata;
    isAuthenticated;
    get authStatus() {
        if (!this.user)
            return AuthStatus.UNAUTHENTICATED;
        if (!this.token)
            return AuthStatus.AUTHENTICATING;
        return AuthStatus.AUTHENTICATED;
    }
    get displayName() {
        if (!this.user)
            return 'User';
        if (!this.user.displayName)
            return this.user.email.split('@')[0];
        return this.user.displayName;
    }
    /**
     * Initializes the auth store.
     * @param rootStore - The root store instance.
     */
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.observeFirebaseStateChanges();
        this.observeZustandStore();
        this.hydrateAuthState();
        makeAutoObservable(this);
    }
    /**
     * Hydrates the auth state from the persisted state
     */
    hydrateAuthState() {
        const persistedAuthState = appStore.getState().auth;
        if (persistedAuthState.user) {
            this.setUser(persistedAuthState.user);
        }
        if (persistedAuthState.userMetadata) {
            this.setUserMetadata(persistedAuthState.userMetadata);
        }
        this.setIsAuthenticated(persistedAuthState.isAuthenticated);
    }
    /**
     * Observe zustand store changes
     */
    observeZustandStore() {
        appStore.subscribe((state) => {
            this.setIsAuthenticated(state.auth.isAuthenticated);
        });
    }
    /**
     * Observe firebase auth state changes
     */
    observeFirebaseStateChanges() {
        const auth = getAuth(getApp());
        // Triggered on token refresh or sign-in or sign-out
        onIdTokenChanged(auth, async (user) => {
            if (!user)
                return;
            const token = await getFirebaseToken();
            runInAction(() => {
                this.token = token;
            });
        });
        // Triggered on sign-in or sign-out
        onAuthStateChanged(auth, async (user) => {
            if (!user)
                return;
            const token = await getFirebaseToken();
            runInAction(() => {
                this.token = token;
            });
            if (IS_PROD_LIKE_ENV && this.user && this.userMetadata) {
                analytics.initializeAnalytics({
                    email: this.user.email,
                    name: this.user.displayName,
                    entityUrn: this.userMetadata.user_urn
                });
                logger.identifyUser({
                    email: this.user.email,
                    name: this.user.displayName,
                    entityUrn: this.userMetadata.user_urn
                });
            }
        });
    }
    loginError({ error, onError }) {
        logger.error(`Error login user: ${error && error.message}`, {
            error,
            code_area: 'login'
        });
        onError();
        if (error.code === AuthErrorCodes.USER_NOT_FOUND) {
            this.setError('This email isn’t connected to an account. <a class="text-blue-dark focus:underline" href="/signup">Sign up?</a>');
        }
        if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
            this.setError('The password is incorrect for this email address.');
        }
        this.setError(error.message);
    }
    async signInToHarmonic() {
        const authenticateResponse = await authenticate();
        const firebaseAuth = getAuth(getApp());
        const firebaseUser = firebaseAuth.currentUser;
        if (LOCAL_AGAINST_PROD) {
            if (authenticateResponse.customer !== HARMONIC_CUSTOMER_URN) {
                throw new Error('Can only login to Harmonic users in local environment.');
            }
        }
        if (firebaseUser && authenticateResponse.apikey) {
            this.setUser({
                email: firebaseUser.email || '',
                email_verified: firebaseUser.emailVerified,
                user_id: firebaseUser.uid,
                displayName: authenticateResponse.name || firebaseUser.displayName || ''
            });
            this.setUserApiKey(authenticateResponse.apikey);
            this.setUserStatus(authenticateResponse.status);
            this.setUserRole(authenticateResponse.role);
            this.setUserMetadata({
                user_id: getIdFromUrn(authenticateResponse.entity_urn) || '',
                user_urn: authenticateResponse.entity_urn,
                customer_urn: authenticateResponse.customer,
                customer_name: authenticateResponse.customer_name,
                settings: authenticateResponse.settings
            });
        }
        return { authenticateResponse };
    }
    /**
     * Logs out the user.
     * @param reason - The reason for the logout.
     * @param error - The error that occurred during the logout.
     */
    async logout(reason = LogoutReason.UserLoggedOut, error) {
        if (!appStore.getState().auth.isAuthenticated) {
            return;
        }
        appStore.getState().auth.logout();
        appStore.getState().dashboard.resetDashboard();
        try {
            const auth = getAuth(getApp());
            await signOut(auth);
            switch (reason) {
                case LogoutReason.SessionExpired:
                    toast.dark(SESSION_EXPIRED_ERROR_MESSAGE, {
                        autoClose: false
                    });
                    logger.error(`User session expired`, {
                        code_area: 'logout',
                        error
                    });
                    break;
                case LogoutReason.UserLoggedOut:
                default:
                    toast.info('You have been successfully logged out.');
                    usePersistedZustandStore.getState().reset();
                    break;
            }
            sessionStorage.clear();
            localStorage.clear();
        }
        catch (error) {
            logger.error(`Error logging user out: ${error && error.message}`, {
                error,
                code_area: 'logout'
            });
        }
    }
    /**
     * Signs in a user with email and password.
     * @param data - The signin data containing email and password.
     * @param onSuccess - The function to call when the signin is successful.
     * @param onError - The function to call when the signin fails.
     */
    async signin(data, onSuccess, onError) {
        this.setError('');
        this.setLoading(true);
        const { email, password } = data;
        const auth = getAuth(getApp());
        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            if (!response?.user?.emailVerified) {
                sendEmailVerification(response.user);
                this.setError('Email verification required. Check your inbox for a new link.');
                onError();
                this.setLoading(false);
                return;
            }
        }
        catch (error) {
            const functionName = 'authActions/signin';
            this.loginError({
                functionName,
                error: error,
                onError
            });
            this.setLoading(false);
            return;
        }
        const { authenticateResponse } = await this.signInToHarmonic();
        try {
            onSuccess({
                status: 'ok',
                apiKey: authenticateResponse.apikey
            });
        }
        catch (error) {
            onError(error);
        }
        this.setLoading(false);
    }
    /**
     * Logs in a user with SSO.
     * @param response - The response from the SSO login.
     * @param onSuccess - The function to call when the login is successful.
     * @param onError - The function to call when the login fails.
     */
    async loginWithSSO(response, onSuccess, onError) {
        this.setError('');
        this.setLoading(true);
        if (!response?.user) {
            this.setError('No user found. Please reach out to support.');
            onError();
            return;
        }
        if (!response.user.emailVerified) {
            sendEmailVerification(response.user);
            this.setError('Email verification required. Check your inbox for a new link.');
            onError();
            return;
        }
        try {
            const { authenticateResponse } = await this.signInToHarmonic();
            onSuccess({
                status: 'ok',
                apiKey: authenticateResponse.apikey
            });
        }
        catch (error) {
            onError(error);
        }
        this.setLoading(false);
    }
    /**
     * Sends a password reset email to the user.
     * @param email - The email of the user to send the password reset email to.
     * @param successMsg - The message to display when the password reset email is sent successfully.
     * @param onSuccess - The function to call when the password reset email is sent successfully.
     * @param onError - The function to call when the password reset email fails to send.
     */
    async sendPasswordResetEmailHandler(email, successMsg, onSuccess, onError) {
        const auth = getAuth(getApp());
        this.setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            this.setSuccess(successMsg);
            onSuccess();
        }
        catch (error) {
            onError();
            logger.error(`Error resetting user password: ${error && error.message}`, {
                error,
                code_area: 'reset_password'
            });
            this.setError(error.message);
        }
    }
    setUser(user) {
        this.user = user || undefined;
        appStore.getState().auth.setUser(user);
    }
    setUserMetadata(userMetadata) {
        this.userMetadata = userMetadata;
        appStore.getState().auth.setUserMetadata(userMetadata);
        useStore.setState({
            userUrn: userMetadata?.user_urn,
            customerUrn: userMetadata?.customer_urn,
            userSettings: userMetadata?.settings
        });
    }
    setIsAuthenticated(isAuthenticated) {
        this.isAuthenticated = isAuthenticated;
    }
    setError(msg) {
        appStore.getState().auth.setError(msg);
    }
    setSuccess(msg) {
        appStore.getState().auth.setSuccess(msg);
    }
    setUserApiKey(token) {
        appStore.getState().auth.setApiKey(token);
    }
    setUserStatus(status) {
        appStore.getState().auth.setUserStatus(status);
    }
    setUserRole(role) {
        appStore.getState().auth.setUserRole(role);
    }
    setLoading(value) {
        appStore.getState().auth.setLoading(value);
    }
    setNeedVerification() {
        appStore.getState().auth.setNeedVerification();
    }
    setDisplayName({ user, displayName }) {
        if (!user)
            return;
        this.setUser({
            ...user,
            displayName
        });
    }
    setOnboardingSuccess(successState) {
        if (this.userMetadata) {
            this.setUserMetadata({
                ...this.userMetadata,
                settings: {
                    ...this.userMetadata?.settings,
                    has_completed_onboarding: successState
                }
            });
        }
    }
}
export default AuthStore;
