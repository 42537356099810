import { jsx as _jsx } from "react/jsx-runtime";
import { useStore } from 'config/StoreProvider';
import ListStack from 'harmonic-components/ListStack/ListStack';
import { observer } from 'mobx-react';
const CompanySidepanel = () => {
    const { rootStore } = useStore();
    const handleSetCustomFieldValues = (input) => {
        rootStore.companyWatchlistViewStore.setCustomFieldValues(input);
    };
    return (_jsx("div", { className: "w-[400px] p-p40", children: _jsx(ListStack, { listEntries: rootStore.companyViewStore.company?.listEntries || [], onSetCustomFieldValues: handleSetCustomFieldValues }) }));
};
export default observer(CompanySidepanel);
